import { doAuthActionGet } from "../services/httpRequest";

export const FETCH_TOKEN_DETAILS = 'FETCH_TOKEN_DETAILS'

/**
 * action to get access token for fetching data
 * @param {*} token 
 */
export const getAccessToken = (detail) => {
  const request = doAuthActionGet({ url: "token/" + detail.token });
  return request;
}

