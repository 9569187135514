import React, { Component } from 'react';
import IdleTimer from 'react-idle-timer';
import { setIdleTime } from '../../src/actions/idleAppAction';
import { connect } from 'react-redux';

class IdleApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeout: 1800000,
      showModal: false,
      userLoggedIn: false,
    }
    this.idleTimer = null
  }
  onAction = e => {
    this.props.setIdleTime(false);
  }

  onActive = e => {
    this.props.setIdleTime(false);
  }

  onIdle = e => {
    if (this.props.isTimedOut) {
      console.log('isTimedOut ', this.props.isTimedOut);
    } else {
      this.setState({ showModal: true });
      this.props.onAppIdle(true);
      this.idleTimer.reset();
      this.props.setIdleTime(true);
    }

  }

  render() {
    return (
      <>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout} />
      </>
    )

  }

}
const mapStateToProps = (state) => {
  return {
    timeOutStatus: state.idleAppReducer.timeOutStatus,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setIdleTime: (status) => {
      dispatch(setIdleTime(status));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IdleApp)
// export default APP
