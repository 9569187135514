import {
    MY_TASKS_LISTS, MY_TASKS_LISTS_SUCCESS, MY_TASKS_LISTS_FAILURE, UPDATE_USER_DATA_SET,
    UPDATE_TASK_LIST_PAGE_NUMBER, GET_EVENTS, GET_EVENTS_SUCCESS, GET_EVENTS_FAILURE
} from '../../actions/lists/listsActions'

import {
    UPDATE_DEPARTMENT_ID, SHOW_TASK_TAB, GET_DEPART_ID
} from '../../actions/tasks/tasksAction'

const INITIAL_STATE = {
    myTasksList: { myTasksList: undefined, error: null, loading: false },
    updatedUserList: [],
    taskListPage: 1,
    departmentId: { departmentId: undefined },
    showMyTaskTab: false,
    myDepartmentId: { myDepartmentId: undefined },
    selectedId: undefined,
    eventsList: { eventsList: undefined, error: null, loading: false },
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case MY_TASKS_LISTS:
            return {
                ...state, myTasksList: {
                    myTasksList: state.myTasksList ? state.myTasksList : undefined,
                    error: null,
                    loading: false
                }
            }
        case MY_TASKS_LISTS_SUCCESS:
            return {
                ...state, myTasksList: {
                    myTasksList: action.payload.data,
                    error: null,
                    loading: false
                }
            }
        case MY_TASKS_LISTS_FAILURE:
            return {
                ...state, myTasksList: {
                    myTasksList: undefined,
                    error: null,
                    loading: false
                }
            }
        case UPDATE_USER_DATA_SET:
            return { ...state, updatedUserList: action.payload }
        case UPDATE_TASK_LIST_PAGE_NUMBER:
            return { ...state, taskListPage: action.payload.pageNumber }

        case UPDATE_DEPARTMENT_ID:
            return {
                ...state, myDepartmentId: {
                    myDepartmentId: action.payload
                }
            }

        case SHOW_TASK_TAB:
            return {
                ...state, showMyTaskTab: action.payload
            }

        case GET_DEPART_ID:
            return {
                ...state, selectedId: action.payload
            }

            case GET_EVENTS:
                return {
                    ...state, eventsList: {
                        eventsList: state.eventsList ? state.eventsList : undefined,
                        error: null,
                        loading: false
                    }
                }
            case GET_EVENTS_SUCCESS:
                return {
                    ...state, eventsList: {
                        eventsList: action.payload.data,
                        error: null,
                        loading: false
                    }
                }
            case GET_EVENTS_FAILURE:
                return {
                    ...state, eventsList: {
                        eventsList: undefined,
                        error: null,
                        loading: false
                    }
                }

        default:
            return { ...state }
    }
}
