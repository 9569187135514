import React, { Component } from 'react'
import { Route } from 'react-router'
import { Switch, Redirect, BrowserRouter } from 'react-router-dom'
import DynamicImport from '../common/lazyLoadComponent'
import IdlePopUpWarning from '../common/idlePopUp'
import IdleApp from '../common/idleApp';
import { setIdleTime } from '../../src/actions/idleAppAction';
import { connect } from 'react-redux';
import LoaderComponents from '../common/loaderComponent';
import { LandingDashboardPermission, CRMReminder, UNDERWRITINGTasks } from '../common/userPermissions'
import permissionService from '../services/permissionService'
import { AccessDenied } from "../components/common/accessDenied"
import RouteWithUserRole from "./routeWithUserRole"

const LoaderComponent = () => {
	return (
		<div className="loading-container">
			<div className="pulse" />
		</div>
	)
}

const TasksListLoader = (props) => {
	return (
		<DynamicImport load={() => import('../containers/tasks/taskListContainer')}>
			{(Component) => (Component == null ? <LoaderComponent /> : <Component {...props} />)}
		</DynamicImport>
	)
}

const getPathName = (newPathName) => {
	if (newPathName.indexOf('/index.html#') > -1) {
		newPathName = newPathName.replace(window.location.origin, "").replace(/\/index.html#/g, "");
	}
	if (newPathName.indexOf('/index.html') > -1) {
		newPathName = newPathName.replace(window.location.origin, "").replace(/\/index.html/g, "");
	}
	if (newPathName !== "/") {
		newPathName = newPathName.replace(window.location.origin, "");
	}
	return newPathName;
}

let countPermission = 0
class AppRoute extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			userDetails: false,
		}
	}


	handleClose = e => {

		this.props.setIdleTime(false);
	}

	onAppIdle = (isAppIdle) => {
		this.props.setIdleTime(isAppIdle);
	}

	render() {
		if (sessionStorage.getItem("currentUser") && !countPermission) {
			countPermission++
			const user = JSON.parse(sessionStorage.getItem("currentUser"));
			permissionService.setPermissions(user.permissions);
		}
		if (!sessionStorage.getItem("currentUser"))
			return <LoaderComponent />
		else {
			var newPathName = window.location.href
			return (
				<div>
					{
						!sessionStorage.currentUser ?
							<div>
								<LoaderComponents />
							</div>
							:
							<div>
								{!localStorage.getItem('showIdlePopup') && permissionService.hasPermission(LandingDashboardPermission.Read) ?
									<IdlePopUpWarning handleClose={this.handleClose} open={this.props.timeOutStatus} /> : null}
								<IdleApp onAppIdle={this.onAppIdle} />
								<BrowserRouter>
									<Redirect to={getPathName(newPathName)} />
									<Switch>
										/**
											* there is no use of route /tasks 
											* till the time dashboard redirection in servicing and processing module is not changed
											* keeping as it is.
											*/
										<Route exact path="/tasks">
											<Redirect from="/tasks" to="/" />
										</Route>
										<RouteWithUserRole exact path="/" component={TasksListLoader}
											roleName={LandingDashboardPermission} />
										<RouteWithUserRole exact path="/myreminders" component={TasksListLoader}
											roleName={CRMReminder} />
										<RouteWithUserRole exact path="/mytasks" component={TasksListLoader}
											roleName={LandingDashboardPermission} />
										<Route exact path="/accessdenied" component={AccessDenied} />

									</Switch>
								</BrowserRouter>
							</div>
					}
				</div>
			);
		}
	}
}

const mapStateToProps = (state) => {
	return {
		timeOutStatus: state.idleAppReducer.timeOutStatus,
	}
}

let mapDispatchToProps = (dispatch) => {
	return {
		setIdleTime: (status) => {
			dispatch(setIdleTime(status));
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AppRoute)
