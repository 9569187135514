import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router"
import { Provider } from 'react-redux';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore.js';
import createBrowserHistory from 'history/createBrowserHistory';
import App from './App';
import { getRefreshToken, getParameterByName } from "./common/requireAuth";
import APIConfig from './services/appConfig';

let waitForSessionStorageToFill = true;


const onAddEventListener = () => {
    // Ask other tabs for session storage
    localStorage.setItem("getSessionStorage", Date.now());
    window.addEventListener("storage", (event) => {
    if (event.key === "getSessionStorage") {
        // Some tab asked for the sessionStorage -> send it

        if (!!sessionStorage.currentUser) {
            localStorage.setItem("setSessionStorage", JSON.stringify({ currentUser: sessionStorage.currentUser }));
            localStorage.removeItem("setSessionStorage");
        }
    }
    if (event.key === "setSessionStorage" || event.key === "updateSessionStorage") {
        // Some tab asked for the sessionStorage -> send it
        const data = JSON.parse(event.newValue);
        if (data) {
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    sessionStorage.setItem(key, data[key]);
                }
            }
        }
        if(event.key === "setSessionStorage") {
            waitForSessionStorageToFill = false
        }
    }
    });
};

const checkLogin = () => {
    const parametersRefreshToken = getParameterByName('token');
    if (parametersRefreshToken) {
        getRefreshToken();
    }
}

onAddEventListener();
APIConfig.loadAPIConfig();
checkLogin();

const store = configureStore();
const history = createBrowserHistory();

setTimeout(()=> { waitForSessionStorageToFill=false}, 5000)
const timeId = setInterval(() => {    
    if(!waitForSessionStorageToFill) {    
        ReactDOM.render(
            <Provider store={store}>
              <Router history={history}>
                <App />
              </Router>
            </Provider>, document.getElementById('root'));
        clearInterval(timeId)    
    }
}, 100)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();