export const AdminMailMerge = {
  Read: 'Admin.MailMerge.Read',
  Write: 'Admin.MailMerge.Write',
};
export const AdminCRMMailMerge = {
  Read: 'Admin.CRMMailMerge.Read',
  Write: 'Admin.CRMMailMerge.Write',
  Delete: 'Admin.CRMMailMerge.Delete',
};
export const AdminUnderwritingMailMerge = {
  Read: 'Admin.UnderwritingMailMerge.Read',
  Write: 'Admin.UnderwritingMailMerge.Write',
  Delete: 'Admin.UnderwritingMailMerge.Delete',
};
export const AdminServicingMailMerge = {
  Read: 'Admin.ServicingMailMerge.Read',
  Write: 'Admin.ServicingMailMerge.Write',
  Delete: 'Admin.ServicingMailMerge.Delete',
};
export const AdminProcessingMailMerge = {
  Read: 'Admin.ProcessingMailMerge.Read',
  Write: 'Admin.ProcessingMailMerge.Write',
  Delete: 'Admin.ProcessingMailMerge.Delete',
};
export const AdminUserPermission = {
  Read: 'Admin.User.Read',
  Write: 'Admin.User.Write',
};
export const AdminRolePermission = {
  Read: 'Admin.Role.Read',
  Write: 'Admin.Role.Write',
};
export const AdminServicingSettingsPermission = {
  Read: 'Servicing.ServicingSettings.Read',
  Write: 'Servicing.ServicingSettings.Write',  
};

export const UnderwritingEvent = {
  Read: 'Underwriting.Event.Read',
  Write: 'Underwriting.Event.Write',
  Lock: 'Underwriting.Event.Lock',
};
export const UnderwritingEventDataDump = {
  Read: 'Underwriting.EventDataDump.Read',
};
export const UnderwritingEventAssignment = {
  Read: 'Underwriting.EventAssignment.Read',
  Write: 'Underwriting.EventAssignment.Write',
};
export const UnderwritingEventDataCut = {
  Read: 'Underwriting.EventDataCut.Read',
  Write: 'Underwriting.EventDataCut.Write',
};
export const UnderwritingEventBidList = {
  Read: 'Underwriting.EventBidList.Read',
  Write: 'Underwriting.EventBidList.Write',
  Delete: 'Underwriting.EventBidList.Delete',
};
export const UnderwritingEventResultsUpload = {
  Write: 'Underwriting.EventResultsUpload.Write',
};
export const UnderwritingReviewPage = {
  Read: 'Underwriting.ReviewPage.Read',
  Write: 'Underwriting.ReviewPage.Write',
};
export const UnderwritingReviewComments = {
  Read: 'Underwriting.ReviewComments.Read',
  Write: 'Underwriting.ReviewComments.Write',
};
export const UnderwritingAttachments = {
  Read: 'Underwriting.Attachments.Read',
  Write: 'Underwriting.Attachments.Write',
  Delete: 'Underwriting.Attachments.Delete',
};
export const UnderwritingPropertyProfile = {
  Read: 'Underwriting.PropertyProfile.Read',
  Write: 'Underwriting.PropertyProfile.Write',
  Delete: 'Underwriting.PropertyProfile.Delete',
};
export const UnderwritingMailMerge = {
  Read: 'Underwriting.MailMerge.Read',
};

export const WorkflowTaskType = {
  Read: 'Workflow.TaskType.Read',
  Write: 'Workflow.TaskType.Write',
};
export const WorkflowWorkflowType = {
  Read: 'Workflow.WorkflowType.Read',
  Write: 'Workflow.WorkflowType.Write',
};
export const WorkflowProcessingTaskType = {
  Read: 'Workflow.ProcessingTaskType.Read',
  Write: 'Workflow.ProcessingTaskType.Write',
};
export const WorkflowUnderwritingTaskType = {
  Read: 'Workflow.UnderwritingTaskType.Read',
  Write: 'Workflow.UnderwritingTaskType.Write',
};
export const WorkflowProcessingWorkflowType = {
  Read: 'Workflow.ProcessingWorkflowType.Read',
  Write: 'Workflow.ProcessingWorkflowType.Write',
};
export const WorkflowUnderwritingWorkflowType = {
  Read: 'Workflow.UnderwritingWorkflowType.Read',
  Write: 'Workflow.UnderwritingWorkflowType.Write',
};

export const CRMRecords = {
  Read: 'CRM.Records.Read',
};
export const CRMProperties = {
  Read: 'CRM.Properties.Read',
};
export const CRMOpportunities = {
  Read: 'CRM.Opportunities.Read',
  Write: 'CRM.Opportunities.Write',
};
export const CRMOpportunitySensitiveData = {
  Read: 'CRM.OpportunitySensitiveData.Read',
  Write: 'CRM.OpportunitySensitiveData.Write',
};
export const CRMContacts = {
  Read: 'CRM.Contacts.Read',
  Write: 'CRM.Contacts.Write',
  Delete: 'CRM.Contacts.Delete',
};
export const CRMCampaigns = {
  Read: 'CRM.Campaigns.Read',
  Write: 'CRM.Campaigns.Write',
};
export const CRMCampaignsDataDump = {
  Read: 'CRM.CampaignsDataDump.Read',
};
export const CRMCampaignComments = {
  Read: 'CRM.CampaignComments.Read',
  Write: 'CRM.CampaignComments.Write',
};
export const CRMRecordComments = {
  Read: 'CRM.RecordComments.Read',
  Write: 'CRM.RecordComments.Write',
};
export const CRMMailMerge = {
  Read: 'CRM.MailMerge.Read',
};

export const CRMReminder = {
  Read: "CRM.Reminder.Read",
  Write: "CRM.Reminder.Write",
  Delete: "CRM.Reminder.Delete"
}

export const ServicingLoanPermission = {
  Read: "Servicing.Loan.Read",
  Write: "Servicing.Loan.Write"
}

export const ServicingAssetListPermission = {
  Read: 'Servicing.AssetList.Read',
}

export const ServicingEventsPermission = {
  Read: 'Servicing.Events.Read',
  Write: 'Servicing.Events.Write',
};

export const ServicingLienReleasePermission = {
  Read: 'Servicing.LienRelease.Read',
  Write: 'Servicing.LienRelease.Write',
};

export const ServicingDataImportPermission = {
  Read: 'Servicing.DataImport.Read',
  Write: 'Servicing.DataImport.Write',
};

export const ServicingDataExportPermission = {
  Read: 'Servicing.DataExport.Read',
  Write: 'Servicing.DataExport.Write',
};

export const ServicingBulkUploadPermission = {
  Read: 'Servicing.BulkUpload.Read',
  Write: 'Servicing.BulkUpload.Write',
};

export const ServicingTransactionImportPermission = {
  Read: 'Servicing.TransactionImport.Read',
  Write: 'Servicing.TransactionImport.Write',
};

export const ServicingLockboxInformationPermission = {
  Read: 'Servicing.LockboxInformation.Read',
  Write: 'Servicing.LockboxInformation.Write',
};

export const ServicingOtherPostingPermission = {
  Read: 'Servicing.OtherPosting.Read',
  Write: 'Servicing.OtherPosting.Write',
};

export const ServicingPendingDecisionsPermission = {
  Read: 'Servicing.PendingDecisions.Read',
  Write: 'Servicing.PendingDecisions.Write',
};

export const ServicingRefundReviewPermission = {
  Read: 'Servicing.Refund.Read',
  Write: 'Servicing.Refund.Write',
};

export const ServicingManageLockBoxPermission = {
  Read: 'Servicing.ManageLockBox.Read',
  Write: 'Servicing.ManageLockBox.Write',
};

export const ServicingManageFinancingGroupPermission = {
  Read: 'Servicing.ManageFinancingGroup.Read',
  Write: 'Servicing.ManageFinancingGroup.Write',
};

export const ServicingGlobalContactsPermission = {
  Read: 'Servicing.GlobalContacts.Read',
  Write: 'Servicing.GlobalContacts.Write'
}

export const ServicingGlobalMailMergePermission = {
  Read: 'Servicing.GlobalMailMerge.Read',
  Write: 'Servicing.GlobalMailMerge.Write',
};

export const LandingDashboardPermission = {
  Read: 'Landing.Dashboard.Read',
};

export const ProcessingLoanPermission = {
  Read: 'Processing.Loan.Read',
  Write: 'Processing.Loan.Write',
};

export const ServicingEventsAndNotesPermission = {
  Read: 'Servicing.EventsAndNotes.Read',
  Write: 'Servicing.EventsAndNotes.Write',
};