import {
    doActionGetWorkflow, doActionPatchWorkflow, doActionPatchServicing
} from '../../services/httpRequest';

import { formatDateTodesired } from '../../components/common/utils'

export const MY_TASKS_LISTS = 'MY_TASKS_LISTS';
export const MY_TASKS_LISTS_SUCCESS = "MY_TASKS_LISTS_SUCCESS";
export const MY_TASKS_LISTS_FAILURE = "MY_TASKS_LISTS_FAILURE";

export const UPDATE_USER_DATA_SET = "UPDATE_USER_DATA_SET";
export const UPDATE_TASK_LIST_PAGE_NUMBER = "UPDATE_TASK_LIST_PAGE_NUMBER";

export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAILURE = 'GET_EVENTS_FAILURE';

export const getAllEventList = (filterParams, calendarDate) => {
    let url = 'tasks/underwriting/getall?' +
        '&Limit=' + filterParams.Limit +
        '&SortOrder=' + filterParams.SortOrder +
        '&SortField=' + filterParams.SortField +
        '&Offset=' + filterParams.Offset +
        '&FullSearch=' + filterParams.FullSearch
    
    if (filterParams.EventTypes) {
        url += '&Filter.EventTypeIds=' + filterParams.EventTypes
    }
    if (filterParams.States) {
        url += '&Filter.StateIds=' + filterParams.States
    }
    for (let i in filterParams.Statuses) {
        url += '&Filter.Statuses=' + filterParams.Statuses[i]
    }
   
    if (filterParams.DepartmentIds) {
        url += '&Filter.DepartmentIds=' + filterParams.DepartmentIds;
        if (filterParams.UserIds) {
            url += '&Filter.UserIds=' + filterParams.UserIds;
        }
    } else {
        if(filterParams.UserIds){
            url += '&Filter.UserIds=' + filterParams.UserIds;
        }    
    }
    if (calendarDate) {
        url += '&Filter.DueDate=' + formatDateTodesired(calendarDate);
    }
    const request = doActionGetWorkflow({ url: url });
    return {
        type: GET_EVENTS,
        payload: request
    };
}

export const getAllEventListSuccess = (data) => {
    return {
        type:GET_EVENTS_SUCCESS,
        payload: data
    };
}

export const getAllEventListFailure = (error) => {
    return {
        type: GET_EVENTS_FAILURE,
        payload: error
    };
}

export const getMyTasksList = (filterParams, calendarDate) => {
    let url = 'tasks/underwriting/getall?' +
        'SortField=' + filterParams.SortField +
        '&SortOrder=' + filterParams.SortOrder +
        '&FullSearch=' + filterParams.FullSearch +
        '&Limit=' + filterParams.Limit +
        '&Offset=' + filterParams.Offset
    if (filterParams.States) {
        url += '&Filter.StateIds=' + filterParams.States
    }
    if (filterParams.EventTypes) {
        url += '&Filter.EventTypeIds=' + filterParams.EventTypes
    }
    for (let i in filterParams.Statuses) {
        url += '&Filter.Statuses=' + filterParams.Statuses[i]
    }
    if (calendarDate) {
        url += '&Filter.DueDate=' + formatDateTodesired(calendarDate);
    }
    if (filterParams.DepartmentIds) {
        url += '&Filter.DepartmentIds=' + filterParams.DepartmentIds;
        // if (filterParams.UserIds) {
        //     url += '&Filter.UserIds=' + filterParams.UserIds;
        // }
        // UserIds.map(users => (
        //     url += '&Filter.UserIds=' + users.Id
        // )
        // );

    } 
    if(filterParams.UserIds){
        url += '&Filter.UserIds=' + filterParams.UserIds;
    }
 
    const request = doActionGetWorkflow({ url: url });
    return {
        type: MY_TASKS_LISTS,
        payload: request
    };
}

export const getMyTasksListSuccess = (data) => {
    return {
        type: MY_TASKS_LISTS_SUCCESS,
        payload: data
    };
}

export const getMyTasksListFailure = (error) => {
    return {
        type: MY_TASKS_LISTS_FAILURE,
        payload: error
    };
}

export const changeActionStatus = (id, actionType, departmentId) => {
    if(departmentId === 9) {
        let url = "task/" + id + "/taskstatus";
        let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        let data = {
            "status": actionType === 1 ? 'InProgress' : 'Completed',
            "userId": currentUser.userId,
            "userName": currentUser.name
          }
        const servicingReq = doActionPatchServicing({
            url: url,
            data: data,
        });
        return {
            type: 'CHANGE_ACTION_STATUS',
            payload: servicingReq
        };
    }
    else {
        let url = "tasks/underwriting/" + id + "/taskstatus";
        let data = JSON.stringify(actionType)
        const request = doActionPatchWorkflow({
            url: url,
            data: data,
        });
        return {
            type: 'CHANGE_ACTION_STATUS',
            payload: request
        };
    }
  
}

export const changeActionStatusSuccess = (data) => {
    return {
        type: 'CHANGE_ACTION_STATUS_SUCCESS',
        payload: data
    };
}

export const changeActionStatusFailure = (error) => {
    return {
        type: 'CHANGE_ACTION_STATUS_FAILURE',
        payload: error
    };
}

// export const completeActionStatus = (id, actionType) => {
//     let url = 'tasks/underwriting/' + id + '/status';
//     let data = JSON.stringify(actionType)
//     const request = doActionPatchWorkflowElastic({
//         url: url,
//         data: data,
//     });
//     return {
//         type: 'COMPLETE_ACTION_STATUS',
//         payload: request
//     };
// }

// export const completeActionStatusSuccess = (data) => {
//     return {
//         type: 'COMPLETE_ACTION_STATUS_SUCCESS',
//         payload: data
//     };
// }

// export const completeActionStatusFailure = (error) => {
//     return {
//         type: 'COMPLETE_ACTION_STATUS_FAILURE',
//         payload: error
//     };
// }

/**
* action to change action status
*/
export const changeActionStatusThen = (id) => {
    let url = 'tasks/underwriting/' + id;

    const request = doActionGetWorkflow({ url: url });
    return {
        type: 'CHANGE_ACTION_STATUS_THEN',
        payload: request
    };
}

export const changeActionStatusThenSuccess = (data) => {
    return {
        type: 'CHANGE_ACTION_STATUS_THEN_SUCCESS',
        payload: data
    };
}

export const changeActionStatusThenFailure = (error) => {
    return {
        type: 'CHANGE_ACTION_STATUS_THEN_FAILURE',
        payload: error
    };
}

/**
* action to update TaskList to create a workflow
*/
export const updateUserList = (userSet) => {
    return {
        type: UPDATE_USER_DATA_SET,
        payload: userSet
    }
}

/**
 * updates the page number of the Tasktype
 * @param {*} pageNumber 
 */
export const updateTaskListPageNumber = (pageNumber) => {
    return {
        type: UPDATE_TASK_LIST_PAGE_NUMBER,
        payload: pageNumber
    }
}