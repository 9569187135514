import React from "react"
import ErrorFoundBg from '../../assets/error-bg.svg'
//// import ErrorFourZeroFour from '../../assets/error404.svg'

export const AccessDenied = () => {
  return (
    <div className="noRecordFound pageError">
      <span className="noRecordText">
        {/* <span className="errorBigText">Whoops!</span> */}
        {"Access Denied."}
        <br />
        {"Please contact your system administrator."}
      </span>
      <img src={ErrorFoundBg} alt="Error Found" />
    </div>
  )
}