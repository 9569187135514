import React, { Component } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import SuccessIcon from '../../assets/success.svg'
import ErrorIcon from '../../assets/error.svg'

class CustomSnackbar extends Component {
	constructor(props) {
		super(props)
		this.state = {
			open: false,
			successMessage: ''
		}

		this.handleClose = this.handleClose.bind(this)
		this.handleClick = this.handleClick.bind(this)
	}

	componentWillMount() {
		if (this.props.message) {
			this.setState({ open: true, successMessage: this.props.message })
		}
	}

	componentDidMount = () => {
		if (this.props.message) {
			this.setState({ open: true, successMessage: this.props.message })
		} else {
			this.setState({ open: false })
		}
	}

	handleClick() {
		this.setState({ open: true })
	}

	handleClose(reason) {
		if (reason === 'clickaway') {
			return
		}
		this.setState({ open: false })
		if (this.props.parentCallback) {
			if (this.state.successMessage === 'Please select atleast two items !' ||
				this.state.successMessage === 'Please select atleast one category') {
				this.props.parentCallback(true)
			} else {
				this.props.parentCallback(false)
			}
		}
		this.setState({ successMessage: '' })
	}

	render() {
		return (
			<div>
				<Snackbar
					id="snackbar-1"
					className={!this.props.type ? "customSnackbar" : "customSnackbar errorClassSnack"}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center'
					}}
					open={this.state.open}
					ContentProps={{
						'aria-describedby': 'snackbarMessage-id'
					}}
					onClose={this.handleClose}
					message={
						<span id="snackbarMessage-id" className="snackbarMessage">
							<span className="messIcon">
							   <img src={SuccessIcon} alt="success" className="snackSuccessIcon" />
								<img src={ErrorIcon} alt="error" className="snackErrorIcon" />
							</span>
							<span className="textSection">{this.state.successMessage}</span>
						</span>
					}
					action={[
						<IconButton
							id="iconBtn"
							key="close"
							aria-label="close"
							color="inherit"
							onClick={this.handleClose}>
							<CloseIcon />
						</IconButton>
					]}
					autoHideDuration={5000}
				/>
			</div>
		)
	}
}

export default CustomSnackbar
