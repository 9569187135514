
import { doActionGetLanding, doActionGet, doActionPost, doActionPatch, doActionPut, doActionGetWorkflow, doActionNotification, doActionPatchWorkflow, doDeleteNotification, doActionPatchServicing, doActionGetServicing } from '../../services/httpRequest';

import { formatDateTodesired } from '../../components/common/utils'

export const USERS_LISTS = 'USERS_LISTS';
export const USERS_LISTS_SUCCESS = "USERS_LISTS_SUCCESS";
export const USERS_LISTS_FAILURE = "USERS_LISTS_FAILURE";

export const EVENTS_LIST = 'EVENTS_LIST';
export const EVENTS_LIST_SUCCESS = "EVENTS_LIST_SUCCESS";
export const EVENTS_LIST_FAILURE = "EVENTS_LIST_FAILURE";

export const NOTIFICATIONS_LISTS = 'NOTIFICATIONS_LISTS';
export const NOTIFICATIONS_LISTS_SUCCESS = "NOTIFICATIONS_LISTS_SUCCESS";
export const NOTIFICATIONS_LISTS_FAILURE = "NOTIFICATIONS_LISTS_FAILURE";

export const SAVE_NEW_REMINDER_DETAILS = 'SAVE_NEW_REMINDER_DETAILS'
export const SAVE_NEW_REMINDER_DETAILS_SUCCESS = 'SAVE_NEW_REMINDER_DETAILS_SUCCESS'
export const SAVE_NEW_REMINDER_DETAILS_FAILURE = 'SAVE_NEW_REMINDER_DETAILS_FAILURE'

export const REMINDER_LISTS = 'REMINDER_LISTS';
export const REMINDER_LISTS_SUCCESS = "REMINDER_LISTS_SUCCESS";
export const REMINDER_LISTS_FAILURE = "REMINDER_LISTS_FAILURE";

export const REMINDER_BY_ID = 'REMINDER_BY_ID';
export const REMINDER_BY_ID_SUCCESS = "REMINDER_BY_ID_SUCCESS";
export const REMINDER_BY_ID_FAILURE = "REMINDER_BY_ID_FAILURE";

export const DELETE_NOTIFICATION_BY_ID = "DELETE_NOTIFICATION_BY_ID";
export const DELETE_NOTIFICATION_BY_ID_FAILURE = "DELETE_NOTIFICATION_BY_ID_FAILURE";

export const GET_NOTE_VALUE = "GET_NOTE_VALUE";
export const GET_NOTE_VALUE_SUCCESS = "GET_NOTE_VALUE_SUCCESS";
export const GET_NOTE_VALUE_FAILURE = "GET_NOTE_VALUE_FAILURE";

export const EDIT_NOTE = "EDIT_NOTE";
export const EDIT_NOTE_SUCCESS = "EDIT_NOTE_SUCCESS";
export const EDIT_NOTE_FAILURE = "EDIT_NOTE_FAILURE";

export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAILURE = "GET_NOTIFICATION_FAILURE";

export const STATES_LIST = "STATES_LIST";
export const STATES_LIST_SUCCESS = "STATES_LIST_SUCCESS";
export const STATES_LIST_FAILURE = "STATES_LIST_FAILURE";

export const EVENT_TYPES_LIST = "EVENT_TYPES_LIST";
export const EVENT_TYPES_LIST_SUCCESS = "EVENT_TYPES_LIST_SUCCESS";
export const EVENT_TYPES_LIST_FAILURE = "EVENT_TYPES_LIST_FAILURE";

export const ALL_EVENT_LIST = "ALL_EVENT_LIST";
export const ALL_EVENT_LIST_SUCCESS = "ALL_EVENT_LIST_SUCCESS";
export const ALL_EVENT_LIST_FAILURE = "ALL_EVENT_LIST_FAILURE";

export const FETCH_ALL_OPPORTUNITIES = "FETCH_ALL_OPPORTUNITIES"
export const FETCH_ALL_OPPORTUNITIES_SUCCESS = "FETCH_ALL_OPPORTUNITIES_SUCCESS"
export const FETCH_ALL_OPPORTUNITIES_FAILURE = "FETCH_ALL_OPPORTUNITIES_FAILURE"

export const FETCH_ALL_RECORDS = 'FETCH_ALL_RECORDS'
export const FETCH_ALL_RECORDS_SUCCESS = 'FETCH_ALL_RECORDS_SUCCESS'
export const FETCH_ALL_RECORDS_FAILURE = 'FETCH_ALL_RECORDS_FAILURE'

export const UPDATE_SAVE_REMINDER_FORM = 'UPDATE_SAVE_REMINDER_FORM'
export const UPDATE_VALIDATE_REMINDER = 'UPDATE_VALIDATE_REMINDER'

export const EDIT_REMINDER_BY_ID = 'EDIT_REMINDER_BY_ID'
export const EDIT_REMINDER_BY_ID_SUCCESS = 'EDIT_REMINDER_BY_ID_SUCCESS'
export const EDIT_REMINDER_BY_ID_FAILURE = 'EDIT_REMINDER_BY_ID_FAILURE'

export const CANCEL_REMINDER_BY_ID = 'CANCEL_REMINDER_BY_ID'
export const CANCEL_REMINDER_BY_ID_SUCCESS = 'CANCEL_REMINDER_BY_ID_SUCCESS'
export const CANCEL_REMINDER_BY_ID_FAILURE = 'CANCEL_REMINDER_BY_ID_FAILURE'

export const FETCH_ALL_CONTACTS = 'FETCH_ALL_CONTACTS'
export const FETCH_ALL_CONTACTS_SUCCESS = 'FETCH_ALL_CONTACTS_SUCCESS'
export const FETCH_ALL_CONTACTS_FAILURE = 'FETCH_ALL_CONTACTS_FAILURE'

export const FETCH_CONTACT_LIST_BY_ID = 'FETCH_CONTACT_LIST_BY_ID'
export const FETCH_CONTACT_LIST_BY_ID_SUCCESS = 'FETCH_CONTACT_LIST_BY_ID_SUCCESS'
export const FETCH_CONTACT_LIST_BY_ID_FAILURE = 'FETCH_CONTACT_LIST_BY_ID_FAILURE'

export const GET_EVENTS = 'GET_EVENTS'
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS'
export const GET_EVENTS_FAILURE = 'GET_EVENTS_FAILURE'

export const UPDATE_DEPARTMENT_ID = 'UPDATE_DEPARTMENT_ID'
export const SHOW_TASK_TAB = 'SHOW_TASK_TAB'
export const GET_DEPART_ID = 'GET_DEPART_ID'

export const GET_TABS_ENABLE_STATUS = "GET_TABS_ENABLE_STATUS"
export const GET_TABS_ENABLE_STATUS_SUCCESS = "GET_TABS_ENABLE_STATUS_SUCCESS"
export const GET_TABS_ENABLE_STATUS_FAILURE = "GET_TABS_ENABLE_STATUS_FAILURE"

export const GET_TASK_DATE_BY_MONTH = "GET_TASK_DATE_BY_MONTH"
export const GET_TASK_DATE_BY_MONTH_SUCCESS = "GET_TASK_DATE_BY_MONTH_SUCCESS"
export const GET_TASK_DATE_BY_MONTH_FAILURE = "GET_TASK_DATE_BY_MONTH_FAILURE"

const getOpportunityUrl = (searchOppParam) => {
    let finalUrl = "SortField=" + searchOppParam.sortField + "&SortOrder=" + searchOppParam.sortOrder + "&FullSearch=" + searchOppParam.fullSearch + "&limit=" + searchOppParam.limit + "&offset=" + searchOppParam.offset
    let leadIdParam, campaignIdParam

    searchOppParam.filter.campaignIds.forEach((campaignId) => {
        campaignIdParam += "Filter.CampaignIds=" + campaignId
    })
    if (campaignIdParam)
        finalUrl = campaignIdParam + "&" + finalUrl

    searchOppParam.filter.leadIds.forEach((leadId) => {
        leadIdParam += "Filter.LeadIds=" + leadId
    })
    if (leadIdParam)
        finalUrl = leadIdParam + "&" + finalUrl
    return finalUrl
}
export const getAllOpportunities = (data) => {
    const searchOppParam = data.searchOppParam
    const finalUrl = getOpportunityUrl(searchOppParam)
    const request = doActionGet({ url: "opportunities?" + finalUrl })
    return {
        type: FETCH_ALL_OPPORTUNITIES,
        payload: request
    }
}
/**
* success action to fetch all oppotunities on the basis of search params
*/
export const getAllOpportunitiesSuccess = (details) => {
    return {
        type: FETCH_ALL_OPPORTUNITIES_SUCCESS,
        payload: details
    }
}
/**
 * failure action to fetch all oppotunities on the basis of search params
 */
export const getAllOpportunitiesFailure = (error) => {
    return {
        type: FETCH_ALL_OPPORTUNITIES_FAILURE,
        payload: error
    };
}
/**
* action to fetch all records on the basis of searchParam
*/
export const getAllContacts = (data) => {
    const searchRecordParam = data.searchRecordParam
    const request = doActionGet({
        url:
            'contacts?SortField=' +
            searchRecordParam.sortField +
            '&SortOrder=' +
            searchRecordParam.sortOrder +
            '&FullSearch=' +
            searchRecordParam.fullSearch +
            '&Limit=' +
            searchRecordParam.limit +
            '&Offset=' +
            searchRecordParam.offset
    })
    return {
        type: FETCH_ALL_CONTACTS,
        payload: request
    }
}
/**
 * success action for fetching all records on the basis of searchParam
 */
export const getAllContactsSuccess = (details) => {
    return {
        type: FETCH_ALL_CONTACTS_SUCCESS,
        payload: details
    }
}
/**
 * failure action for fetching all records on the basis of searchParam
 */
export const getAllContactsFailure = (error) => {
    return {
        type: FETCH_ALL_CONTACTS_FAILURE,
        payload: error
    }
}
export const getAllRecords = (data) => {
    const searchRecordParam = data.searchRecordParam
    const request = doActionGet({
        url:
            'leads?SortField=' +
            searchRecordParam.sortField +
            '&SortOrder=' +
            searchRecordParam.sortOrder +
            '&FullSearch=' +
            searchRecordParam.fullSearch +
            '&Limit=' +
            searchRecordParam.limit +
            '&Offset=' +
            searchRecordParam.offset
    })
    return {
        type: FETCH_ALL_RECORDS,
        payload: request
    }
}
/**
 * success action for fetching all records on the basis of searchParam
 */
export const getAllRecordsSuccess = (details) => {
    return {
        type: FETCH_ALL_RECORDS_SUCCESS,
        payload: details
    }
}
/**
 * failure action for fetching all records on the basis of searchParam
 */
export const getAllRecordsFailure = (error) => {
    return {
        type: FETCH_ALL_RECORDS_FAILURE,
        payload: error
    }
}
export const getContactListById = (id) => {
    let finalUrl = id === '' ? 'contacts?Limit=1000&Offset=0' : 'contacts?Filter.LeadIds=' + id + '&Limit=1000&Offset=0'

    const request = doActionGet({ url: finalUrl })
    return {
        type: FETCH_CONTACT_LIST_BY_ID,
        payload: request
    }
}
export const getContactListByIdSuccess = (details) => {
    return {
        type: FETCH_CONTACT_LIST_BY_ID_SUCCESS,
        payload: details
    }
}

export const getContactListByIdFailure = (error) => {
    return {
        type: FETCH_CONTACT_LIST_BY_ID_FAILURE,
        payload: error
    }
}
export const getUsersList = () => {
    let url = 'Dictionaries/Users?limit=10000';
    const request = doActionGetLanding({ url: url });
    return {
        type: USERS_LISTS,
        payload: request
    }

}

export const getUsersListSuccess = (data) => {
    return {
        type: USERS_LISTS_SUCCESS,
        payload: data
    };
}

export const getUsersListFailure = (error) => {
    return {
        type: USERS_LISTS_FAILURE,
        payload: error
    };
}

export const getEventsList = () => {
    let currentUser = JSON.parse(sessionStorage.getItem('currentUser')).userId
    let url = 'reminder/remindertaskaggregation?userId=' + currentUser;

    const request = doActionGet({ url: url });
    return {
        type: EVENTS_LIST,
        payload: request
    };
}

export const getEventsListSuccess = (data) => {
    return {
        type: EVENTS_LIST_SUCCESS,
        payload: data
    };
}

export const getEventsListFailure = (error) => {
    return {
        type: EVENTS_LIST_FAILURE,
        payload: error
    };
}
export const updateValidateReminder = (createReminder) => {
    return {
        type: UPDATE_VALIDATE_REMINDER,
        payload: createReminder
    }
}

/**
 * Edit Reminders
 */
export const editReminderById = (id, details) => {
    let data = JSON.stringify(details)
    const request = doActionPut({ url: 'reminder/' + id, data })
    return {
        type: EDIT_REMINDER_BY_ID,
        payload: request
    }
}
export const editReminderByIdSuccess = (details) => {
    return {
        type: EDIT_REMINDER_BY_ID_SUCCESS,
        payload: details
    }
}

export const editReminderByIdFailure = (error) => {
    return {
        type: EDIT_REMINDER_BY_ID_FAILURE,
        payload: error
    }
}
export const updateSaveReminderForm = (createReminder) => {
    return {
        type: UPDATE_SAVE_REMINDER_FORM,
        payload: createReminder
    }
}
/**
 * cancel Reminder
 */

export const cancelReminderById = (id, details) => {
    let data = JSON.stringify(details)
    const request = doActionPatch({ url: 'reminder/' + id, data })
    return {
        type: CANCEL_REMINDER_BY_ID,
        payload: request
    }
}
export const cancelReminderByIdSuccess = (details) => {
    return {
        type: CANCEL_REMINDER_BY_ID_SUCCESS,
        payload: details
    }
}

export const cancelReminderByIdFailure = (error) => {
    return {
        type: CANCEL_REMINDER_BY_ID_FAILURE,
        payload: error
    }
}

export const getReminderList = () => {
    let currentUser = JSON.parse(sessionStorage.getItem('currentUser')).userId
    let url = 'reminder?SortField=SheduledDate&SortOrder=Desc&Filter.CreatedById=' + currentUser;

    const request = doActionGet({ url: url });
    return {
        type: REMINDER_LISTS,
        payload: request
    };
}

export const getReminderListSuccess = (data) => {
    return {
        type: REMINDER_LISTS_SUCCESS,
        payload: data
    };
}

export const getReminderListFailure = (error) => {
    return {
        type: REMINDER_LISTS_FAILURE,
        payload: error
    };
}
export const saveNewReminderDetails = (data) => {
    const request = doActionPost({ url: 'reminder', data })
    return {
        type: SAVE_NEW_REMINDER_DETAILS,
        payload: request
    }
}
export const saveNewReminderDetailsSuccess = (details) => {
    return {
        type: SAVE_NEW_REMINDER_DETAILS_SUCCESS,
        payload: details
    }
}

export const saveNewReminderDetailsFailure = (error) => {
    return {
        type: SAVE_NEW_REMINDER_DETAILS_FAILURE,
        payload: error
    }
}
export const getReminderById = (id) => {
    let url = 'reminder?Filter.Id=' + id

    const request = doActionGet({ url: url });
    return {
        type: REMINDER_BY_ID,
        payload: request
    };
}

export const getReminderByIdSuccess = (data) => {
    return {
        type: REMINDER_BY_ID_SUCCESS,
        payload: data
    };
}

export const getReminderByIdFailure = (error) => {
    return {
        type: REMINDER_BY_ID_FAILURE,
        payload: error
    };
}
export const getNotificationsList = () => {
    let url = 'notificationmessage';

    const request = doActionNotification({ url: url });
    return {
        type: NOTIFICATIONS_LISTS,
        payload: request
    };
}

export const getNotificationsListSuccess = (data) => {
    return {
        type: NOTIFICATIONS_LISTS_SUCCESS,
        payload: data
    };
}

export const getNotificationsListFailure = (error) => {
    return {
        type: NOTIFICATIONS_LISTS_FAILURE,
        payload: error
    };
}

export const deleteNotificationById = (Id) => {
    let url = 'notificationmessage/' + Id;

    const request = doDeleteNotification({ url: url });
    return {
        type: DELETE_NOTIFICATION_BY_ID,
        payload: request
    }
}

export const deleteNotificationByIdFailure = (error) => {
    return {
        type: DELETE_NOTIFICATION_BY_ID_FAILURE,
        payload: error
    };
}

export const getNoteValue = (Id, departmentId) => {
    if(departmentId === 9) {
        let url = 'task/' + Id;
        const request = doActionGetServicing({ url: url });
        return {
            type: GET_NOTE_VALUE,
            payload: request
        }
    }
    else {
        let url = 'tasks/underwriting/' + Id;
        const request = doActionGetWorkflow({ url: url });
        return {
            type: GET_NOTE_VALUE,
            payload: request
        }
    }
}

export const getNoteValueSuccess = (data) => {
    return {
        type: GET_NOTE_VALUE_SUCCESS,
        payload: data
    }
}

export const getNoteValueFailure = (err) => {
    return {
        type: GET_NOTE_VALUE_FAILURE,
        payload: err
    }
}

export const editNote = (Id, Data, departmentId) => {
    if(departmentId === 9) {
        let url = 'notes';
        let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        const request = doActionPatchServicing({
            url: url,
            data: {
                "taskId": Id,
                "notes": Data,
                "userId": currentUser.userId,
                "userName": currentUser.name
              }
        });
    
        return {
            type: EDIT_NOTE,
            payload: request
        }
    }
    else {
        let url = 'tasks/underwriting/' + Id + '/notes';

        const request = doActionPatchWorkflow({
            url: url,
            data: JSON.stringify(Data)
        });
    
        return {
            type: EDIT_NOTE,
            payload: request
        }
    }
}

export const editNoteSuccess = (data) => {
    return {
        type: EDIT_NOTE_SUCCESS,
        payload: data
    }
}

export const editNoteFailure = (err) => {
    return {
        type: EDIT_NOTE_FAILURE,
        payload: err
    }
}

export const getNotification = (Id, departmentId) => {
    if(departmentId === 9) {
        let url = 'task/' + Id;
        const request = doActionGetServicing({ url: url });
        return {
            type: GET_NOTIFICATION,
            payload: request
        }
    }
    else {
        let url = 'tasks/underwriting/' + Id;
        const request = doActionGetWorkflow({ url: url });
        return {
            type: GET_NOTIFICATION,
            payload: request
        }
    }
}

export const getNotificationSuccess = (data) => {
    return {
        type: GET_NOTIFICATION_SUCCESS,
        payload: data
    }
}

export const getNotificationFailure = (err) => {
    return {
        type: GET_NOTIFICATION_FAILURE,
        payload: err
    }
}
export const getStatesList = () => {
    let url = 'Dictionaries/States';

    const request = doActionGetLanding({ url: url });
    return {
        type: STATES_LIST,
        payload: request
    };
}

export const getStatesListSuccess = (data) => {
    return {
        type: STATES_LIST_SUCCESS,
        payload: data
    };
}

export const getStatesListFailure = (error) => {
    return {
        type: STATES_LIST_FAILURE,
        payload: error
    };
}

export const getEventTypesList = () => {
    let url = 'Dictionaries/EventTypes';

    const request = doActionGetLanding({ url: url });
    return {
        type: EVENT_TYPES_LIST,
        payload: request
    };
}

export const getEventTypesListSuccess = (data) => {
    return {
        type: EVENT_TYPES_LIST_SUCCESS,
        payload: data
    };
}

export const getEventTypesListFailure = (error) => {
    return {
        type: EVENT_TYPES_LIST_FAILURE,
        payload: error
    };
}

export const getAllEventsList = (departmentIds, calendarDate) => {
    let userId = JSON.parse(sessionStorage.getItem('currentUser')).userId;
   if (departmentIds.length > 0) {
       let url = "tasks/underwriting/getall?Filter.Statuses=1&Filter.Statuses=3&limit=1";

       if (calendarDate) {
           url += '&Filter.DueDate=' + formatDateTodesired(calendarDate);
       }

       const request = doActionGetWorkflow({
          url: [`${url}&Filter.UserIds=${userId}`, ...departmentIds.map((departmentId) => `${url}&Filter.DepartmentIds=${departmentId}`)],
        });
       return {
           type: ALL_EVENT_LIST,
           payload: request
       };
    }
}

export const getAllEventsListSuccess = (data) => {
    return {
        type: ALL_EVENT_LIST_SUCCESS,
        payload: data
    };
}

export const getAllEventsListFailure = (error) => {
    return {
        type: ALL_EVENT_LIST_FAILURE,
        payload: error
    };
}


export const getEventList = (calendarDate, departmentId) => {
    let url1 = "tasks/underwriting/getall?Filter.Statuses=1&Filter.Statuses=3&limit=6";
    if (calendarDate) {
        url1 += '&Filter.DueDate=' + formatDateTodesired(calendarDate);
    }
    if (departmentId) {
        url1 += '&Filter.DepartmentIds=' + departmentId;
    }

    const request = doActionGetWorkflow({
        url: url1
    });
    return {
        type: GET_EVENTS,
        payload: request
    };
}

export const getEventListSuccess = (data) => {
    return {
        type: GET_EVENTS_SUCCESS,
        payload: data
    };
}

export const getEventListFailure = (error) => {
    return {
        type: GET_EVENTS_FAILURE,
        payload: error
    };
}

export const updateDepartmentId = (departmentId) => {
    return {
        type: UPDATE_DEPARTMENT_ID,
        payload: departmentId
    }
}
export const selectDepartmentId = (departmentId) => {
    return {
        type: GET_DEPART_ID,
        payload: departmentId
    }
}

export const showMyTaskTabOnly = (status) => {
    return {
        type: SHOW_TASK_TAB,
        payload: status
    }
}
 
export const getTabsEnableStatus = (filterParams, calendarDate) => {

    let url = 'tasks/underwriting/getdepartmentstatus?' 
    if (filterParams.States) {
        url += '&Filter.StateIds=' + filterParams.States
    }
    if (filterParams.EventTypes) {
        url += '&Filter.EventTypeIds=' + filterParams.EventTypes
    }
    for (let i in filterParams.Statuses) {
        url += '&Filter.Statuses=' + filterParams.Statuses[i]
    }
    if (filterParams.UserIds) {
        url += '&Filter.UserIds=' + filterParams.UserIds;
    }
    if (calendarDate) {
        url += '&Filter.DueDate=' + formatDateTodesired(calendarDate);
    }
   
    const request = doActionGetWorkflow({ url: url });
    return {
        type: GET_TABS_ENABLE_STATUS,
        payload: request
    }

}

export const getTabsEnableStatusSuccess = (data) => {
    return {
        type: GET_TABS_ENABLE_STATUS_SUCCESS,
        payload: data
    };
}

export const getTabsEnableStatusFailure = (error) => {
    return {
        type: GET_TABS_ENABLE_STATUS_FAILURE,
        payload: error
    };
}

export const getTaskDatesByMonth = (userId, fromDate, toDate) => {
    let url = 'tasks/underwriting/listbyfilter?userId=' + userId + '&from=' + fromDate + '&to=' + toDate;

    const request = doActionGetWorkflow({ url: url });
    return {
        type: GET_TASK_DATE_BY_MONTH,
        payload: request
    };
}

export const getTaskDatesByMonthSuccess = (data) => {
    return {
        type: GET_TASK_DATE_BY_MONTH_SUCCESS,
        payload: data
    };
}

export const getTaskDatesByMonthFailure = (error) => {
    return {
        type: GET_TASK_DATE_BY_MONTH_FAILURE,
        payload: error
    };
}
