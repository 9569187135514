class APIConfig {
    //  crmApi: 'https://crm_api.cazcreekuat.com',

    static get ROOT_URL() { return localStorage.getItem('crmApi'); }

    static get AUTH_URL() { return localStorage.getItem('authApi'); }

    static get LANDING_URL() { return localStorage.getItem('landingApi'); }

    static get ADMIN_URL() { return localStorage.getItem('adminApi'); }

    static get NOTIFICATION_URL() { return localStorage.getItem('notificationApi'); }

    static get UNDERWRITING_URL() { return localStorage.getItem('underwritingApi'); }

    static get WORKFLOW_URL() { return localStorage.getItem('workflowsApi'); }

    static get CRM_ELASTIC_URL() { return localStorage.getItem('crmElasticApi'); }

    static get SERVICING_URL() { return localStorage.getItem('servicingApi'); }

    static get ACCOUNTING_URL() { return localStorage.getItem('accountingApi'); }

    static loadAPIConfig() {
        localStorage.removeItem('crmApi');
        localStorage.removeItem('authApi');
        localStorage.removeItem('landingApi');
        localStorage.removeItem('adminApi');
        localStorage.removeItem('underwritingApi');
        localStorage.removeItem('workflowsApi');
        localStorage.removeItem('notificationApi');
        localStorage.removeItem('crmElasticApi');
        localStorage.removeItem('servicingApi');
        localStorage.removeItem('accountingApi');

        if (window.appConfig) {
            var api = window.appConfig.crmApi + '/api';
            localStorage.setItem('crmApi', api);
            var authApi = window.appConfig.authApi + '/api';
            localStorage.setItem('authApi', authApi);
            var landingApi = window.appConfig.landingApi + '/api';
            localStorage.setItem('landingApi', landingApi);
            var adminApi = window.appConfig.adminApi + '/api';
            localStorage.setItem('adminApi', adminApi);
            var notificationApi = window.appConfig.notificationApi + '/api';
            localStorage.setItem('notificationApi', notificationApi);
            var underwritingApi = window.appConfig.underwritingApi + '/api';
            localStorage.setItem('underwritingApi', underwritingApi);
            var workFlowApi = window.appConfig.workflowsApi + '/api';
            localStorage.setItem('workflowsApi', workFlowApi);
            var crmElasticApi = window.appConfig.crmElasticApi + '/api';
            localStorage.setItem('crmElasticApi', crmElasticApi);
            var servicingApi = window.appConfig.servicingApi;
            localStorage.setItem('servicingApi', servicingApi);
            var accountingApi = window.appConfig.accountingApi;
            localStorage.setItem('accountingApi', accountingApi);
        }
    }
};

export default APIConfig;