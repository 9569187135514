import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import TasksReducer from './tasks/tasksReducer'
import MyTaskListReducer from './lists/myTaskListReducer'
import appReducer from "./appReducer";
import idleAppReducer from '../reducers/idleAppReducer';

const rootReducer = combineReducers({
  routing: routerReducer,
  tasksReducer: TasksReducer,
  myTaskListReducer : MyTaskListReducer,
  appReducer,
  idleAppReducer : idleAppReducer
})

export default rootReducer
