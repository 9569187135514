import React from 'react';
import Loader from 'react-loaders'

function LoaderComponent() {
  return (
    <div className="backgroundOpaceLoader">
      <Loader type="ball-pulse" />
    </div>
  );
}

export default (LoaderComponent);