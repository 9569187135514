import React, { Fragment, useState } from 'react';
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { lastMinuteTimer } from '../../common/const'

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(3)
    }
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(0, 3, 3, 3),
        justifyContent: 'center'
    }
}))(MuiDialogActions)



export default function SessionOut(props) {
    const renderTime = value => {
        if (value === 0) {
            return <div className="timer timeUp">Expired...</div>;
        }

        return (
            <div className="timer">
                <div className="text">Redirecting in</div>
                <div className="value">{value}</div>
                <div className="text">seconds</div>
            </div>
        );
    };

    const [showBtn, setShowBtn] = useState(true)

    const hideBtn = () => {
        setShowBtn(false)
    }

    return (
        <Fragment>
            <Dialog
                id="deleteCommentsDialog"
                open={props.open}
                fullWidth={true}
                maxWidth={'sm'}
            >
                <div className="widthaddReminder sessionOutDialog">
                    <DialogTitle id="addReminderTitle" className="sessionOutTitleBg">
                        <div className="barStriped">
                            <CountdownCircleTimer
                                isPlaying={!props.hasTimeExceeded ? true : false}
                                durationSeconds={lastMinuteTimer}
                                colors={!props.hasTimeExceeded ? [["#F7F639", 0.33], ["#E6C400", 0.33], ["#F7F639", 0.33], ["#E6C400", 0.33],] : [["#8fa767", 1]]}
                                renderTime={renderTime}
                                onComplete={hideBtn}
                                size={130}
                            />
                        </div>
                    </DialogTitle >
                    <DialogContent>
                        <Typography variant="body1" className="questionText">
                            Your session has expired.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        {
                            showBtn && !props.hasTimeExceeded &&
                            <Button
                                type="submit"
                                onClick={props.staySignin}
                                variant="contained"
                                color="primary"
                                className="fab_btn">
                                Stay Signed In
                            </Button>
                        }
                        <Button
                            onClick={props.logout}
                            className="fab_btn">
                            {showBtn && !props.hasTimeExceeded ? 'Cancel' : 'OK'}
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </Fragment>
    );
}