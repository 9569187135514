import { doActionNotification } from '../services/httpRequest'
// import { saveNotification } from '../actions/appAction'
// import store from '../store/configureStore'
// import axios from 'axios'

// const operationId = axios.interceptors.response.headers.get('x-correlation-id')
// + operationId
export const getNotificationAction = async (id) => {
	const response = await doActionNotification({ url: 'operations/' + id })
	return response
}
