import {
	SET_IDLE_TIMEOUT
} from '../actions/idleAppAction';


const INITIAL_STATE = {
	timeOutStatus: false,
}

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_IDLE_TIMEOUT:
			return {
				...state,
				timeOutStatus: state.timeOutStatus ? state.templateDetails : true,
			}
		default:
			return { ...state }
	}
}
