export const getInitialsOfName = (name) => {
	let fullName = name.split(' ')
	return fullName[0].charAt(0).toUpperCase() + fullName[fullName.length - 1].charAt(0).toUpperCase()
}

export const a11Props = (index) => {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

export const formatDateTodesired = (date) => {
	if (date) {
		let d = new Date(date);
		let month = '' + (d.getMonth() + 1);
		let day = '' + d.getDate();
		let year = d.getFullYear();

		if (month.length < 2)
			month = '0' + month;
		if (day.length < 2)
			day = '0' + day;

		return [year, month, day].join('-');
	} else {
		return null;
	}
}
/**
 * Post formatted Date
 */
export const postFormattedDate = (date, time) => {
	let localDate = ""
	if (time !== undefined)
		localDate = new Date(date).toLocaleString('en-US').split(',')[0] + new Date(time).toLocaleString('en-US').split(',')[1]
	else localDate = new Date(date).toLocaleString('en-US').split(',')

	var utc = new Date(localDate).toUTCString(localDate),
		month = '' + (new Date(utc).getUTCMonth() + 1),
		day = '' + new Date(utc).getUTCDate(),
		year = new Date(utc).getUTCFullYear()

	if (month.length < 2) month = '0' + month
	if (day.length < 2) day = '0' + day

	return [year, month, day].join('-')
}


/**
 * Post formatted Time
 */
export const postFormattedTime = (date) => {
	var t = new Date(date).toUTCString(date),
		hours = '' + new Date(t).getUTCHours(),
		minutes = '' + new Date(t).getUTCMinutes(),
		seconds = new Date(t).getUTCSeconds()

	if (hours.length < 2) hours = '0' + hours
	if (minutes.length < 2) minutes = '0' + minutes
	if (seconds.length < 2) seconds = '0' + seconds

	return [hours, minutes, seconds].join(':')
}
export const getFormattedDate = (fileStartDate) => {
	const newDate = new Date(fileStartDate)
	return (
		String(newDate.getMonth() + 1).padStart(2, '0') +
		'-' +
		String(newDate.getDate()).padStart(2, '0') +
		'-' +
		newDate.getFullYear()
	)
}

export const getFormattedDateForCalendar = (fileStartDate) => {
	const newDate = new Date(fileStartDate)
	return (
		String(newDate.getMonth() + 1).padStart(2, '0') +
		'/' +
		String(newDate.getDate()).padStart(2, '0') +
		'/' +
		newDate.getFullYear()
	)
}
/**
 * returns formatted Time
 */
export const getFormattedTime = (time) => {
	time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time]
	if (time.length > 1) {
		// If time format correct
		time = time.slice(1) // Remove full string match value
		time[5] = +time[0] < 12 ? 'AM' : 'PM' // Set AM/PM
		time[0] = +time[0] % 12 || 12 // Adjust hours
	}
	return time.join('')
}
export const formatDate = (date) => {
	if (date) {
		let d = new Date(date);
		let month = '' + (d.getMonth() + 1);
		let day = '' + d.getDate();
		let year = d.getFullYear();

		if (month.length < 2)
			month = '0' + month;
		if (day.length < 2)
			day = '0' + day;

		return [month, day, year].join('/');
	} else {
		return null;
	}
}

export const getMaxRows = () => {
	var total = window.innerHeight
	var top = 161
	var bottom = 83
	var rowHeight = 49
	return Math.floor((total - top - bottom) / rowHeight)
}
export const formatDate_test = (date_test) => {
	date_test = date_test ? (date_test + '').split('T')[0] + 'T00:00:00Z' : null;
	if (date_test) {
		let d = new Date(date_test);
		let day = '' + d.getDate();
		let year = d.getFullYear();
		let month = '' + (d.getMonth() + 1);

		if (day.length < 2)
			day = '0' + day;
		if (month.length < 2)
			month = '0' + month;
		return [month, day, year].join('/');
	} else {
		return null;
	}
}
export const combineDateAndTime = (date, time) => {
	if (date && time) {
		return date.split('T')[0] + 'T' + time + 'Z'
	}

};
/** get local date & time from UTC  */
export const getLocalDate = (utcDateTime) => {
	const dateLocal = new Date(utcDateTime).toLocaleString('en-US')
	const time = new Date(dateLocal).toLocaleTimeString('en-US', {
		hour: '2-digit',
		minute: '2-digit',
		// second: '2-digit',
		hour12: true
	})
	// const date = new Date(dateLocal).toLocaleDateString('en-US')
	return getFormattedDate(dateLocal) + ', ' + time
}

/** get local date & time from UTC  */
export const getLocalDateForCalendar = (utcDateTime) => {
	const dateLocal = new Date(utcDateTime).toLocaleString('en-US')
	const time = new Date(dateLocal).toLocaleTimeString('en-US', {
		hour: '2-digit',
		minute: '2-digit',
		// second: '2-digit',
		hour12: true
	})
	// const date = new Date(dateLocal).toLocaleDateString('en-US')
	return getFormattedDateForCalendar(dateLocal) + ', ' + time
}

export const redirectToRecord = (LeadId) => {
	window.location.href = window.location.protocol + "//" + window.location.hostname + "/crmnew/records-details/" + LeadId;
}
export const redirectToOpportunity = (oppId) => {
	window.location.href = window.location.protocol + "//" + window.location.hostname + "/crmnew/opportunity-details/" + oppId;
}

export const convertIntoCalendarFormat = (utcDateTime) => {
	if(utcDateTime){
	let utcDate = utcDateTime.split('T')[0]
	let utcArr = utcDate.split('-')
	return (
		utcArr[1] + '/' + utcArr[2] + '/' + utcArr[0]
	)
  }
}