import React from 'react';
import CustomSnackbar from '../components/common/CustomSnackbar';

export default function IdlePopUpWarning(props) {
  React.useEffect(() => {
    setTimeout(() => {
      localStorage.setItem('showIdlePopup', 1)
    }, 5000)
  });
  return (
    <div>
      <CustomSnackbar
        open={props.open}
        parentCallback={props.handleClose}
        message="Please note, if you are inactive for 30 minutes, your session will expire. 
                 Make sure you save all the data."
      />
    </div>
  );
}
