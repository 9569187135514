import axios from 'axios'
import FileSaver from 'file-saver';
import APIConfig from './appConfig';
import { getSessionToken, logout, setUser } from '../common/requireAuth';
import { getNotificationAction } from '../common/notification'
import { Guid } from 'guid-typescript';

const getToken = () => sessionStorage.currentUser ? getSessionToken(sessionStorage.currentUser) : null;

const requestBody = {
	Progress: 0,
	Code: { Id: 202, Name: "Accepted" },
	Message: "Accepted"
}

const badRequestBody = {
	Progress: null,
	Code: { Id: 500, Name: "Internal Server Error" },
	Message: "Something went wrong. Please try again later!"
}

const getNotification = async (response) => {
	const id = response.headers['x-correlation-id']
	const result = await getNotificationAction(id)

	if (result) {
		if (result.status === 200) {
			if (!result.data) {
				sessionStorage.setItem('notification', JSON.stringify(requestBody))
				setTimeout(async function () {
					await getNotification(response)
				}, 5000)
			} else {
				sessionStorage.setItem('notification', JSON.stringify(result.data))
				if (result.data.Message === "BadRequest") {
					sessionStorage.setItem('notification', JSON.stringify(result.data))
					console.log("Bad Request")
				} else if (result.data.Message === "InProgress") {
					sessionStorage.setItem('notification', JSON.stringify(result.data))
					setTimeout(async function () {
						await getNotification(response)
					}, 5000)
				} else if (result.data.Progress !== null) {
					sessionStorage.setItem('notification', JSON.stringify(result.data))
					setTimeout(async function () {
						await getNotification(response)
					}, 5000)
				} else if (result.data.Message === "Accepted") {
					sessionStorage.setItem('notification', JSON.stringify(result.data))
					setTimeout(async function () {
						await getNotification(response)
					}, 5000)
				} else if (result.data.Message === "OK" && result.data.Progress === null) {
					sessionStorage.setItem('notification', JSON.stringify(result.data))
					return
				} else if (result.data.Code.Id === 307) {
					sessionStorage.setItem('notification', JSON.stringify(result.data))
					setTimeout(async function () {
						await getNotification(response)
					}, 5000)
				}
			}
		} else if (result.status === 204) {
			sessionStorage.setItem('notification', JSON.stringify(requestBody))
			setTimeout(async function () {
				await getNotification(response)
			}, 5000)
		} else {
			sessionStorage.setItem('notification', JSON.stringify(badRequestBody))
		}
	} else {
		sessionStorage.setItem('notification', JSON.stringify(badRequestBody))
	}
}

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
	failedQueue.forEach(prom => {
		if (error) {
			prom.reject(error);
		} else {
			prom.resolve(token);
		}
	})

	failedQueue = [];
}
// Add a response interceptor
axios.interceptors.response.use(
	async function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		if (response.status === 202) {
			if(response.headers["x-correlation-id"] !== undefined)
				await getNotification(response)
		}
		return response

	}, function (error) {
		const originalRequest = error.config;
		if (error && error.response) {
			if (error.response.status === 404) {
				console.log("error==", error)
				logout();
				//return Promise.reject(error);
			}
			if (error.response.status === 401 && !originalRequest._retry) {
				const guid = Guid.create();
				if (isRefreshing) {
					return new Promise(function (resolve, reject) {
						failedQueue.push({ resolve, reject })
					}).then(access => {
						originalRequest.headers['Authorization'] = 'Bearer ' + access;
						originalRequest.headers['X-Correlation-ID'] = `${guid}`
						return axios(originalRequest);
					}).catch(err => {
						return Promise.reject(err);
					})
				}

				originalRequest._retry = true;
				isRefreshing = true;

				const tokenItem = JSON.parse(sessionStorage.getItem('currentUser'))
				const refreshToken = tokenItem.refreshToken;

				return new Promise(function (resolve, reject) {
					axios.get(`${APIConfig.AUTH_URL}/token/${refreshToken}`).then(response => {
						if (response) {
							originalRequest.headers['Authorization'] = 'Bearer ' + response.data.access;
							originalRequest.headers['X-Correlation-ID'] = `${guid}`

							localStorage.setItem('updateSessionStorage', JSON.stringify({ currentUser: JSON.stringify(setUser(response.data)) }));
							localStorage.removeItem('updateSessionStorage');
							setUser(response.data);

							processQueue(null, response.data.access);
							resolve(axios(originalRequest));
						} else {
							logout()
							return Promise.reject(error);
						}
					})
						.catch((err) => {
							processQueue(err, null);
							logout();
							reject(err);
						})
						.then(() => { isRefreshing = false })
				});
			}
		}
		return Promise.reject(error);
	});


/**
 * action for authentication http request for notification
 */
export function doActionNotification(config) {
	// const token = sessionStorage.currentUser ? getSessionToken(sessionStorage.currentUser) : null

	if (config) {
		return axios({
			url: `${APIConfig.NOTIFICATION_URL}/` + config.url,
			method: 'GET',
			headers: {
				Authorization: getToken(),
				'Content-Type': 'application/json',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
			}
		})
	}
}

/**
 * action for authentication http request for delete Notification
 */
export function doDeleteNotification(config) {
	if (config) {
		return axios({
			url: `${APIConfig.NOTIFICATION_URL}/` + config.url,
			method: 'DELETE',
			headers: {
				Authorization: getToken(),
				'Content-Type': 'application/json'
			}
		})
	}
}

/**
 * action for authentication http request for get
 */
export function doAuthActionGet(config) {
	if (config) {
		return axios({
			url: `${APIConfig.AUTH_URL}/` + config.url,
			method: 'GET'
		})
	}
}

/**
* action for http request for post
* @param {*} config
*/
export function doActionPost(config) {
	if (config) {
		return axios({
			url: `${APIConfig.ROOT_URL}/` + config.url,
			method: 'POST',
			data: config.data,
			headers: {
				Authorization: getToken(),
				'Content-Type': 'application/json',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
			}
		})
	}
}

/**
* action for http request for post
* @param {*} config
*/
export function doActionPatch(config) {
	if (config) {
		return axios({
			url: `${APIConfig.ROOT_URL}/` + config.url,
			method: 'PATCH',
			data: config.data,
			headers: {
				Authorization: getToken(),
				'Content-Type': 'application/json',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
			}
		})
	}
}

/**
* action for http request for post
* @param {*} config
*/
export function doActionPut(config) {
	if (config) {
		return axios({
			url: `${APIConfig.ROOT_URL}/` + config.url,
			method: 'PUT',
			data: config.data,
			headers: {
				Authorization: getToken(),
				'Content-Type': 'application/json',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
			}
		})
	}
}

/**
* action for http request for get
* @param {*} config
*/
export function doActionGet(config) {
	if (config) {
		return axios({
			url: `${APIConfig.ROOT_URL}/` + config.url,
			method: 'GET',
			headers: {
				Authorization: getToken(),
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
			},
			'axios-retry': {
				retries: 3
			}
		})
	}
}

/**
* action for http request for delete
* @param {*} config
*/
export function doActionDelete(config) {
	if (config) {
		return axios({
			url: `${APIConfig.ROOT_URL}/` + config.url,
			method: 'DELETE',
			data: config.data,
			headers: {
				Authorization: getToken(),
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
			}
		})
	}
}

/**
* action for http request for get for landing url
* @param {*} config
*/
export function doActionGetLanding(config) {
	if (config) {
		return axios({
			url: `${APIConfig.LANDING_URL}/` + config.url,
			method: 'GET',
			headers: {
				Authorization: getToken(),
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
			}
		})
	}
}
/**
* action for http request for get for undewriting url
* @param {*} config
*/
export function doActionGetUnderwriting(config) {
	if (config) {
		return axios({
			url: `${APIConfig.UNDERWRITING_URL}/` + config.url,
			method: 'GET',
			headers: { Authorization: getToken() }
		})
	}
}

/**
 * action for http request of get for admin url
 */
export function doActionGetAdmin(config) {
	if (config) {
		return axios({
			url: `${APIConfig.ADMIN_URL}/` + config.url,
			method: 'GET',
			headers: {
				Authorization: getToken(),
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
			}
		})
	}
}
/**
 * action for download opportunity
 */
export function doActionGetDownloadPdf(config) {
	if (config) {
		axios.get(`${APIConfig.ROOT_URL}/${config.Url}`, {
			headers: { Authorization: getToken() },
			responseType: 'blob',
		}).then(response => {
			const filename = config.fileName;
			FileSaver.saveAs(response.data, filename);
		});
	}
}

/**
 * action for http request of export data download
 */
export function doActionGetDownload(config) {
	console.log("config: ", config)
	if (config) {
		axios.get(config.Url, {
			responseType: 'blob',
		}).then(response => {
			FileSaver.saveAs(response.data, config.filename);
		});
	}
}

/**
 * action for http request of mail merge file upload
 */
export function doActionPutUpload(config) {
	if (config) {
		return axios({
			url: config.UploadUrl,
			method: 'PUT',
			data: config.File,
			headers: {
				'Content-Type': ''
			}
		})
	}
}

/**
 * action for http request of get for admin url
 */
export function doActionGetWorkflow(config) {

	if (config) {
		if (Array.isArray(config.url)) {
            const requests = config.url.map((newUrl) =>
                axios({
                    url: `${APIConfig.WORKFLOW_URL}/` + newUrl,
                    method: "GET",
                    headers: {
                        Authorization: getToken(),
                    }
                }));

            return axios.all(requests);
        }
        else {
            return axios({
                url: `${APIConfig.WORKFLOW_URL}/` + config.url,
                method: "GET",
                headers: { Authorization: getToken() },
            });    
        }
	}
}

/**
* action for http request for get
* @param {*} config
*/
export function doActionGetServicing(config) {
    if (config) {
		let url = localStorage.getItem('servicingApi');
        return axios({
            url: `${url}/` + config.url,
            method: 'GET',
            headers: {
				Authorization: getToken()
			}
        });
    }
}

/**
* action for http request for get
* @param {*} config
*/
export function doActionGetAccounting(config) {
    if (config) {
		let url = localStorage.getItem('accountingApi');
        return axios({
            url: `${url}/` + config.url,
            method: 'GET',
            headers: {
				Authorization: getToken()
			}
        });
    }
}

/**
 * action for http patch request of servicing url
 */
export function doActionPatchServicing(config) {
	if (config) {
		return axios({
			url: `${APIConfig.SERVICING_URL}/` + config.url,
			method: 'PATCH',
			data: config.data,
			headers: {
				Authorization: getToken(),
				'Content-Type': 'application/json-patch+json'
			}
		})
	}
}

/**
 * action for http patch request of workflow url
 */
export function doActionPatchWorkflow(config) {
	if (config) {
		return axios({
			url: `${APIConfig.WORKFLOW_URL}/` + config.url,
			method: 'PATCH',
			data: config.data,
			headers: {
				Authorization: getToken(),
				'Content-Type': 'application/json-patch+json'
			}
		})
	}
}

/**
* action for http request for get ELASTIC SEARCH
* @param {*} config
*/
export function doActionGetWorkflowElastic(config) {
	if (config) {
		return axios({
			// url: `https://crmelastic_sandbox.cazcreekdev.com/api/` + config.url,
			url: `${APIConfig.CRM_ELASTIC_URL}/` + config.url,
			method: 'GET',
			headers: {
				Authorization: getToken(),
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
			}
		})
	}
}


/**
 * action for http patch request of workflow url
 */
export function doActionPatchWorkflowElastic(config) {
	if (config) {
		return axios({
			// url: `https://crmelastic_sandbox.cazcreekdev.com/api/` + config.url,
			url: `${APIConfig.CRM_ELASTIC_URL}/` + config.url,
			method: 'PATCH',
			data: config.data,
			headers: {
				Authorization: getToken(),
				'Content-Type': 'application/json-patch+json'
			}
		})
	}
}