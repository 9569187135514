import React, { Fragment, Component } from 'react';
import './App.css';
import AppRoute from './routes';
import { logout, getRefreshToken, getParameterByName } from "./common/requireAuth";
import { connect } from 'react-redux';
import { setIdleTime } from '../src/actions/idleAppAction';
import APIConfig from './services/appConfig';
import axios from 'axios';
import SessionOut from './components/common/sessionOutDialog';
import { sessionTimer } from './common/const'
var callingSequence = 0
export class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showSessionOutPopup: false,
      showStayBtn: true,
      hasTimeExceeded: false
    }

    this.loadAppRoute = true;
    this.idleTime = 0;

    //Increment the idle time counter every minute.
    this.idleInterval = setInterval(this.timerIncrement, 60000); // 1 minute

    //Zero the idle timer on mouse movement.
    document.onmousemove = this.resetTimer
    document.onkeypress = this.resetTimer
    document.onclick = this.resetTimer

  }

  resetTimer = () => {
    this.idleTime = 0;
  }

  timerIncrement = () => {
    this.idleTime = this.idleTime + 1;
    if (this.idleTime === sessionTimer) {
      callingSequence = 1
      this.setState({
        showSessionOutPopup: true
      })
          } else if (this.idleTime > sessionTimer) {
              clearInterval(this.idleInterval);
              if (callingSequence !== 2) {
                callingSequence = 3
                this.setState({
                  showSessionOutPopup: true,
                  hasTimeExceeded: true
                })
              }
            }
  }

  onAddEventListener = () => {
    window.addEventListener('storage', event => {
      if (event.key === 'updateSessionStorage') {
        const data = JSON.parse(event.newValue);
        if (data) {
          for (const key in data) {
            if (data.hasOwnProperty(key)) {
              sessionStorage.setItem(key, data[key]);
            }
          }
        }
      }
    });
  }


  componentDidMount() {
    const parametersRefreshToken = getParameterByName('token');

    if (parametersRefreshToken) {
      localStorage.removeItem('showIdlePopup')
    }

    if(!!sessionStorage.currentUser) {
      getRefreshToken(this.props);
    }
    else if (parametersRefreshToken == null || (!!parametersRefreshToken && parametersRefreshToken=='')) {
      logout()
    }
  }
  

  staySignin = () => {
    this.props.staySignIn().then(res => {
      clearInterval(this.idleInterval);
      this.idleInterval = setInterval(this.timerIncrement, 60000);
      this.idleTime = 0;
      callingSequence = 0
      this.handleClose();
    }).catch(err => {
      console.log(err)
    })
  }

  handleClose = () => {
    this.setState({ showSessionOutPopup: false });
  }

  sessionLogout = () => {
    this.handleClose()
    logout()
  }

  render() {
    this.resetTimer()
    if (this.idleTime === sessionTimer && callingSequence !== 3) {
            callingSequence = 2
          }
    return (
      <Fragment>
        <SessionOut
          open={this.state.showSessionOutPopup}
          staySignin={this.staySignin}
          handleClose={this.handleClose}
          showStayBtn={this.state.showStayBtn}
          logout={this.sessionLogout}
          hasTimeExceeded={this.state.hasTimeExceeded}
        />

        <div>{this.props.children}</div>
        <AppRoute />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    timeOutStatus: state.idleAppReducer.timeOutStatus,
    loaderStatus: state.appReducer.loaderStatus
  }
}

export const mapDispatchToProps = (dispatch) => {
  return {
    setIdleTime: (status) => {
      dispatch(setIdleTime(status));
    },

    staySignIn: () => {

      const tokenItem = JSON.parse(sessionStorage.getItem('currentUser'))
      const refreshToken = tokenItem.refreshToken;

      return new Promise(function (resolve, reject) {
          axios.get(`${APIConfig.AUTH_URL}/token/${refreshToken}`).then(data => {
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.data.access;

          // tokenItem = JSON.parse(sessionStorage.getItem('currentUser'));
          tokenItem.refreshToken = data.data.refresh;
          tokenItem.token = data.data.access;
          tokenItem.refreshTokenExp = Math.floor(new Date(data.data.sessionExpires).getTime() / 1000);
          sessionStorage.setItem('currentUser', JSON.stringify(tokenItem))
          resolve();
        })
      })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)

