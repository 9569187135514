import jwt_decode from "jwt-decode"
import { getAccessToken } from "../actions/getAuthAction"
import { beforeExpiry, checkInterval } from "../common/const"
import APIConfig from '../services/appConfig';
import permissionService from "../services/permissionService"

var timer = null;
/**
 * returns the token of current user
 * @param {*} currentUser 
 */
export const getSessionToken = (currentUser) => {
  const sessionCurrentUser = JSON.parse(currentUser)
  return "Bearer " + sessionCurrentUser.token
}
/**
 * decodes the access token for getting the data of current user
 * @param {*} token 
 */
export const getDecodedAccessToken = (token) => {
  try {
    return jwt_decode(token);
  } catch (Error) {
    return null;
  }
}
/**
 * maps the decoded data received from decoding the access token to the keys required
 * @param {*} userData 
 * @param {*} tokens 
 */
export const mapDecodedAccessToken = (userData, tokens) => {
  const nameKey = Object.keys(userData).find(key => key.indexOf('name') > -1 && key.indexOf('nameidentifier') === -1);
  const emailKey = Object.keys(userData).find(key => key.indexOf('email') > -1);
  const userIdKey = Object.keys(userData).find(key => key.indexOf('nameidentifier') > -1);
  const rolesKey = Object.keys(userData).find(key => key.indexOf('role') > -1);
  const name = userData[nameKey];
  const email = userData[emailKey];
  const userId = userData[userIdKey];
  const globalContactId = userData['GlobalContactId'] || '';
  const departments = JSON.parse(userData['Departments'] || '[]');
  let permissions = JSON.parse(userData['Permissions']);
  const roles = JSON.parse(userData[rolesKey] || '[]');
  // permissions = combineComplexPermissions(permissions);
  return {
    token: tokens.access,
    refreshToken: tokens.refresh,
    name,
    email,
    userId,
    exp: userData.exp,
    refreshTokenExp: Math.floor(new Date(tokens.sessionExpires).getTime() / 1000),
    AppModule: userData['AppModule'],
    permissions,
    departments,
    roles,
    globalContactId
  };
}
/**
 * sets the current user data in session storage
 * @param {*} responseData 
 */
export const setUser = (responseData) => {
  const decodedAccessToken = getDecodedAccessToken(responseData.access);
  const user = mapDecodedAccessToken(decodedAccessToken, responseData);
  // const userPermission = setPermissions(user.permissions)
  permissionService.setPermissions(user.permissions);
  // user.userPermission = userPermission
  sessionStorage.setItem('currentUser', JSON.stringify(user));
  startTimer();
  return user
}

export const getParameterByName = (name) => {
  const url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);

  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}


/**
 * returns the new access token using refresh token
 */
export const getRefreshToken = (props) => {

  const currentUser = sessionStorage.currentUser ? JSON.parse(sessionStorage.currentUser) : undefined
  const currentUserRefreshToken = currentUser ? currentUser.refreshToken : '';

  const parametersRefreshToken = getParameterByName('token');
  const refreshToken = currentUserRefreshToken || parametersRefreshToken;

  if (refreshToken && !sessionStorage.getItem('currentUser')) {
    return getAccessToken({ token: refreshToken }).then((response) => {
      let { data } = response;
      if (data) {
        localStorage.setItem('updateSessionStorage', JSON.stringify({ currentUser: JSON.stringify(setUser(data)) }));
        localStorage.removeItem('updateSessionStorage');
        setUser(data);
        const redirectUrl = sessionStorage.getItem('requestedUrl');
        if (!!redirectUrl) {
          sessionStorage.removeItem('requestedUrl');
          window.location.assign(redirectUrl); //redirect when session has other url

        }
        else {
          window.location.reload();
        }
      }
    }).catch(err => {
      console.log('getAccessToken err', err);
    });
  } else if (sessionStorage.getItem('currentUser')) {
    startTimer(props);
  } else {
    logout();
  }
}


const startTimer = (props) => {
  if (!!timer)
    stopTimer();
  timer = setInterval(function () {
    checkExpiry(props);
  }, checkInterval);
}

const stopTimer = () => {
  clearInterval(timer);
}


const checkExpiry = (props) => {
  const currentUser = sessionStorage.currentUser ? JSON.parse(sessionStorage.currentUser) : undefined;
  if (currentUser && currentUser.refreshTokenExp) {
    const expiry = currentUser.refreshTokenExp * 1000; // ms
    const now = new Date(new Date().toUTCString()).getTime(); // ms

    if (now > (expiry - beforeExpiry)) {
      stopTimer();
      getAccessToken({ token: currentUser.refreshToken }).then((response) => {
        let { data } = response;
        if (data) {
          localStorage.setItem('updateSessionStorage', JSON.stringify({ currentUser: JSON.stringify(setUser(data)) }));
          localStorage.removeItem('updateSessionStorage');
          setUser(data);
        }
      });

    }
  } else {
    stopTimer();
    logout();
  }
};


export const logout = () => {
  sessionStorage.removeItem('currentUser');
  localStorage.removeItem('showIdlePopup');
  if (!sessionStorage.currentUser && APIConfig.AUTH_URL) {
    sessionStorage.setItem("requestedUrl", window.location.href.split('?token')[0])
    window.location.assign(`${APIConfig.AUTH_URL}/SSO`);
  }
}
