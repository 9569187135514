import { doActionGetAdmin, doActionGetAccounting } from '../services/httpRequest'

export const SHOW_LOADER = 'SHOW_LOADER'
export const FETCH_USER_DETAILS = 'FETCH_USER_DETAILS'
export const FETCH_USER_DETAILS_SUCCESS = 'FETCH_USER_DETAILS_SUCCESS'
export const FETCH_USER_DETAILS_FAILURE = 'FETCH_USER_DETAILS_FAILURE'
export const SAVE_NOTIFICATION = 'SAVE_NOTIFICATION'
export const FETCH_PENDING_DECISIONS_LIST = 'FETCH_PENDING_DECISIONS_LIST'
export const FETCH_PENDING_DECISIONS_LIST_SUCCESS = 'FETCH_PENDING_DECISIONS_LIST_SUCCESS'
export const FETCH_PENDING_DECISIONS_LIST_FAILURE = 'FETCH_PENDING_DECISIONS_LIST_FAILURE'

/**
 * renders loader on screen on update of props
 * @param {*} showLoader 
 */
export const showLoader = (showLoaderStatus) => {
	return {
		type: SHOW_LOADER,
		payload: showLoaderStatus
	}
}
/**
 * action to fetch all data of loggedIn user along-with roles assigned to user
 */
export const fetchUserDetails = () => {
	const request = doActionGetAdmin({ url: 'users/' + JSON.parse(sessionStorage.currentUser).userId })
	return {
		type: FETCH_USER_DETAILS,
		payload: request
	}
}
/**
 * success action to fetch all data of loggedIn user along-with roles assigned to user
 */
export const fetchUserDetailsSuccess = (details) => {
	return {
		type: FETCH_USER_DETAILS_SUCCESS,
		payload: details
	}
}
/**
 * failure action to fetch all data of loggedIn user along-with roles assigned to user
 */
export const fetchUserDetailsFailure = (error) => {
	return {
		type: FETCH_USER_DETAILS_FAILURE,
		payload: error
	}
}

/**
 * save notification api response
 */
export const saveNotification = (data) => {
	return {
		type: SAVE_NOTIFICATION,
		payload: data
	}
}

export const getPendingDecisionsList = () => {
	const request = doActionGetAccounting({ url: 'transactionDecision' });
	return {
		type: FETCH_PENDING_DECISIONS_LIST,
		payload: request
	}
}

//method call when pending decisions list successfully fetched
export const getPendingDecisionsListSuccess = (data) => {
	return {
		type: FETCH_PENDING_DECISIONS_LIST_SUCCESS,
		payload: data
	}
}

//method call when pending decisions list fail to fetch
export const getPendingDecisionsListFailure = (error) => {
	return {
		type: FETCH_PENDING_DECISIONS_LIST_FAILURE,
		payload: error
	}
}
