class PermissionsService {
  permissions = [];

  constructor() {
    this.permissions = {
      Admin: {
        MailMerge: {
          Read: false,
          Write: false,
          Delete: false
        },
        CRMMailMerge: {
          Read: false,
          Write: false,
          Delete: false,
        },
        UnderwritingMailMerge: {
          Read: false,
          Write: false,
          Delete: false,
        },
        ServicingMailMerge: {
          Read: false,
          Write: false,
          Delete: false,
        },
        ProcessingMailMerge: {
          Read: false,
          Write: false,
          Delete: false,
        },
        User: {
          Read: false,
          Write: false,
        },
        Role: {
          Read: false,
          Write: false,
        },
      },
      Underwriting: {
        Event: {
          Read: false,
          Write: false,
          Lock: false,
        },
        EventDataDump: {
          Read: false,
        },
        EventAssignment: {
          Read: false,
          Write: false,
        },
        EventDataCut: {
          Read: false,
          Write: false,
        },
        EventBidList: {
          Read: false,
          Write: false,
          Delete: false,
        },
        EventResultsUpload: {
          Write: false,
        },
        ReviewPage: {
          Read: false,
          Write: false,
        },
        ReviewComments: {
          Read: false,
          Write: false,
        },
        Attachments: {
          Read: false,
          Write: false,
          Delete: false,
        },
        PropertyProfile: {
          Read: false,
          Write: false,
          Delete: false,
        },
        MailMerge: {
          Read: false,
        },
      },
      Workflow: {
        TaskType: {
          Read: false,
          Write: false,
        },
        WorkflowType: {
          Read: false,
          Write: false,
        },
        UnderwritingTaskType: {
          Read: false,
          Write: false,
        },
        UnderwritingWorkflowType: {
          Read: false,
          Write: false,
        },
        ProcessingTaskType: {
          Read: false,
          Write: false,
        },
        ProcessingWorkflowType: {
          Read: false,
          Write: false,
        },
      },
      CRM: {
        Dashboard: {
          Read: false,
        },
        Records: {
          Read: false,
        },
        Properties: {
          Read: false,
        },
        Opportunities: {
          Read: false,
          Write: false,
        },
        OpportunitySensitiveData: {
          Read: false,
          Write: false,
        },
        Contacts: {
          Read: false,
          Write: false,
        },
        Reminder: {
          Read: false,
          Write: false,
          Delete: false
        },
        Campaigns: {
          Read: false,
          Write: false,
        },
        CampaignsDataDump: {
          Read: false,
          Write: false,
        },
        CampaignComments: {
          Read: false,
          Write: false,
        },
        RecordComments: {
          Read: false,
          Write: false,
        },
        MailMerge: {
          Read: false,
        },
      },
      Landing: {
        Dashboard: {
          Read: false,
        },
      },
      Processing: {
        Loan: {
          Read: false,
        },
      },
      Servicing: {
        Loan: {
          Read: false,
        },
        EventsAndNotes: {
          Read: false,
          Write: false
        },
        Events: {
          Read: false,
          Write: false
        },
        LienRelease: {
          Read: false,
          Write: false
        },
        DataImport: {
          Read: false,
          Write: false
        },
        TransactionImport: {
          Read: false,
          Write: false
        },
        DataExport: {
          Read: false,
          Write: false
        },
        BulkUpload: {
          Read: false,
          Write: false
        },
        LockboxInformation: {
          Read: false,
          Write: false
        },
        OtherPosting: {
          Read: false,
          Write: false
        },
        PendingDecisions: {
          Read: false,
          Write: false
        },
        Refund: {
          Read: false,
          Write: false
        },
        ManageLockBox: {
          Read: false,
          Write: false
        },
        ManageFinancingGroup: {
          Read: false,
          Write: false
        },
        GlobalContacts: {
          Read: false,
          Write: false
        },
        GlobalMailMerge: {
          Read: false,
          Write: false
        },
        AssetList: {
          Read: false,
          Write: false
        },
        ServicingSettings: {
          Read: false,
          Write: false,
        },
      },
    };
  }
  setPermissions(permissions) {
    permissions.forEach(permission => this.setPermission(permission));
    this.setComplexPermissions();
  }

  setPermission(permission) {
    const appNamePermission = permission.split('.')[0];
    const appModulePermission = permission.split('.')[1];
    const permissionType = permission.split('.')[2];

    if (appNamePermission && this.permissions.hasOwnProperty(appNamePermission)) {
      // this.permissions[appNamePermission].App.Read = true;

      if (appModulePermission && this.permissions[appNamePermission].hasOwnProperty(appModulePermission)) {
        this.permissions[appNamePermission][appModulePermission][permissionType] = true;
        if(appNamePermission === "Servicing" && permissionType === "Write") {
          this.permissions[appNamePermission][appModulePermission]["Read"] = true;
        }
      }
    }
  }

  setComplexPermissions = () => {
    this.permissions.Admin.User.Read = (this.permissions.Admin.User.Read && this.permissions.Admin.Role.Read)

    this.permissions.Admin.MailMerge.Read = (this.permissions.Admin.CRMMailMerge.Read || this.permissions.Admin.UnderwritingMailMerge.Read || this.permissions.Admin.ServicingMailMerge.Read || this.permissions.Admin.ProcessingMailMerge.Read);
    this.permissions.Admin.MailMerge.Write = (this.permissions.Admin.CRMMailMerge.Write || this.permissions.Admin.UnderwritingMailMerge.Write || this.permissions.Admin.ServicingMailMerge.Write || this.permissions.Admin.ProcessingMailMerge.Write);
    this.permissions.Admin.MailMerge.Delete = (this.permissions.Admin.CRMMailMerge.Delete || this.permissions.Admin.UnderwritingMailMerge.Delete || this.permissions.Admin.ServicingMailMerge.Delete || this.permissions.Admin.ProcessingMailMerge.Delete);

    this.permissions.Workflow.UnderwritingWorkflowType.Read = this.permissions.Workflow.UnderwritingWorkflowType.Read && this.permissions.Workflow.UnderwritingTaskType.Read
    this.permissions.Workflow.ProcessingWorkflowType.Read = this.permissions.Workflow.ProcessingWorkflowType.Read && this.permissions.Workflow.ProcessingTaskType.Read

    this.permissions.Workflow.TaskType.Read = (this.permissions.Workflow.ProcessingTaskType.Read || this.permissions.Workflow.UnderwritingTaskType.Read);
    this.permissions.Workflow.TaskType.Write = (this.permissions.Workflow.ProcessingTaskType.Write || this.permissions.Workflow.UnderwritingTaskType.Write);

    this.permissions.Workflow.WorkflowType.Read = (this.permissions.Workflow.ProcessingWorkflowType.Read || this.permissions.Workflow.UnderwritingWorkflowType.Read);
    this.permissions.Workflow.WorkflowType.Write = (this.permissions.Workflow.ProcessingWorkflowType.Write || this.permissions.Workflow.UnderwritingWorkflowType.Write);

  }

  hasPermission(permission) {
    const appNamePermission = permission.split('.')[0];
    const appModulePermission = permission.split('.')[1];
    const permissionType = permission.split('.')[2];

    if (appNamePermission && this.permissions.hasOwnProperty(appNamePermission)) {
      if (appModulePermission && this.permissions[appNamePermission].hasOwnProperty(appModulePermission)) {
        return this.permissions[appNamePermission][appModulePermission][permissionType];
      }
    }
    return false;
  }

}

const permissionService = new PermissionsService();
Object.freeze(permissionService);

export default permissionService;