import React from 'react'
import { Route } from 'react-router'
import { Redirect } from 'react-router-dom'
import permissionService from '../services/permissionService'


export default function RouteWithUserRole(props) {
  let { roleName, component, ...others } = props;

  if (permissionService.hasPermission(roleName.Read)) {
    return (<Route exact {...others} component={component} />)
  } else
    return <Redirect to="/accessdenied" />
}