import {
	SHOW_LOADER,
	FETCH_USER_DETAILS,
	FETCH_USER_DETAILS_FAILURE,
	FETCH_USER_DETAILS_SUCCESS,
	SAVE_NOTIFICATION,
	FETCH_PENDING_DECISIONS_LIST,
	FETCH_PENDING_DECISIONS_LIST_SUCCESS,
	FETCH_PENDING_DECISIONS_LIST_FAILURE
} from '../actions/appAction'

const INITIAL_STATE = {
	loaderStatus: false,
	userDetails: undefined,
	notification: {},
	pendingDecisionsList: []
}

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SHOW_LOADER:
			return { ...state, loaderStatus: action.payload }
		case FETCH_USER_DETAILS:
			return { ...state, userDetails: undefined }
		case FETCH_USER_DETAILS_SUCCESS:
			return { ...state, userDetails: action.payload }
		case FETCH_USER_DETAILS_FAILURE:
			return { ...state, userDetails: undefined }
		case SAVE_NOTIFICATION:
			return { ...state, notification: action.payload }
		case FETCH_PENDING_DECISIONS_LIST:
			return { ...state, pendingDecisionsList: [], error: null, loading: true };
		case FETCH_PENDING_DECISIONS_LIST_SUCCESS:
			return { ...state, pendingDecisionsList: action.payload, error: null, loading: false };
		case FETCH_PENDING_DECISIONS_LIST_FAILURE:
			return { ...state, pendingDecisionsList: [], error: action.payload.message, loading: false };		default:
			return { ...state }
	}
}
