export const refreshToken = JSON.parse(sessionStorage.getItem('currentUser')) ?
	JSON.parse(sessionStorage.getItem('currentUser')).refreshToken : "NgOeJum0urQvgRMO76SA";

export const beforeExpiry = 30 * 1000 // 30 seconds
export const checkInterval = 20 * 1000 // 20 seconds

export const sessionTimer = 29 // 29 minutes
export const lastMinuteTimer = 60 // 1 minute
export const baseRoutePathAdmin = "/adminnew"
export const baseRoutePathCRM = "/crmnew"
export const baseRoutePathUnderwritting = "/underwritingnew"
export const baseRoutePathWorkflow = "/workflownew"
export const baseRoutePathProcessing = "/processingnew"
export const getMaxRows = () => {
	var total = window.innerHeight
	var top = 161
	var bottom = 72
	var rowHeight = 49
	return Math.floor((total - top - bottom) / rowHeight)
}

export const taskListPageSize = getMaxRows()

export const searchOppParam = {
	filter: {
		leadIds: [],
		campaignIds: []
	},
	sortField: '',
	sortOrder: 'Desc',
	fullSearch: '',
	limit: 10000,
	offset: 0
}

export const validateReminderForm = {
	LeadId: false,
	UserId: false,
	Description: false,
	SheduledDate: false,
	SheduledTime: false,
	DateFrom: false,
	DateTo: false
}
export const createReminder = {
	LeadId: '',
	UserId: '',
	Description: '',
	OpportunityId: '',
	SheduledDate: '',
	SheduledTime: '',
	ContactId: '',
	Status: '0',
	IsRead: false,
	IsPushNotification: true,
	IsEmailNotification: true
}
export const sampleContact = {
	TotalCount: 1,
	List: [
		{
			Id: 'bd176231-8522-4763-b949-812274b75c2f',
			Lead: {
				Id: '4dc057eb-6c53-40f0-9561-5634d7f12623',
				Name: 'WHITE KATHRYN L 001003'
			},
			Type: {
				Id: 1,
				Name: 'SOS Research'
			},
			FirstName: 'Name 1234',
			LastName: 'Last 1235',
			MiddleName: 'Middle 2965',
			Title: 'Test Title #2965',
			CellPhone: '(121) 212-1212',
			OfficePhone: '(122) 121-2121',
			Email: 'test2965@test.test',
			Address: {
				State: {
					Id: 1,
					Name: 'AL'
				},
				City: 'City 2965',
				Address1: 'Address1 2965',
				Address2: 'Address2 2965',
				Address3: 'Address3 2965',
				Zip: 'Zip 2965'
			}
		}
	]
}