import {
    USERS_LISTS, USERS_LISTS_SUCCESS, USERS_LISTS_FAILURE,
    EVENTS_LIST, EVENTS_LIST_SUCCESS, EVENTS_LIST_FAILURE,
    NOTIFICATIONS_LISTS, NOTIFICATIONS_LISTS_SUCCESS, NOTIFICATIONS_LISTS_FAILURE,
    DELETE_NOTIFICATION_BY_ID, DELETE_NOTIFICATION_BY_ID_FAILURE,
    STATES_LIST, STATES_LIST_SUCCESS, STATES_LIST_FAILURE,
    EVENT_TYPES_LIST, EVENT_TYPES_LIST_SUCCESS, EVENT_TYPES_LIST_FAILURE,
    ALL_EVENT_LIST, ALL_EVENT_LIST_SUCCESS, ALL_EVENT_LIST_FAILURE,
    FETCH_ALL_OPPORTUNITIES,
    FETCH_ALL_OPPORTUNITIES_SUCCESS,
    FETCH_ALL_OPPORTUNITIES_FAILURE,
    FETCH_ALL_RECORDS,
    FETCH_ALL_RECORDS_SUCCESS,
    FETCH_ALL_RECORDS_FAILURE,
    REMINDER_LISTS,
    REMINDER_LISTS_SUCCESS,
    REMINDER_LISTS_FAILURE,
    FETCH_ALL_CONTACTS,
    FETCH_ALL_CONTACTS_SUCCESS,
    FETCH_ALL_CONTACTS_FAILURE,
    REMINDER_BY_ID,
    REMINDER_BY_ID_SUCCESS,
    REMINDER_BY_ID_FAILURE,
    UPDATE_VALIDATE_REMINDER,
    EDIT_REMINDER_BY_ID,
    EDIT_REMINDER_BY_ID_SUCCESS,
    EDIT_REMINDER_BY_ID_FAILURE,
    CANCEL_REMINDER_BY_ID,
    CANCEL_REMINDER_BY_ID_SUCCESS,
    CANCEL_REMINDER_BY_ID_FAILURE,
    UPDATE_SAVE_REMINDER_FORM,
    FETCH_CONTACT_LIST_BY_ID,
    FETCH_CONTACT_LIST_BY_ID_SUCCESS,
    FETCH_CONTACT_LIST_BY_ID_FAILURE,
    SAVE_NEW_REMINDER_DETAILS,
    SAVE_NEW_REMINDER_DETAILS_SUCCESS,
    SAVE_NEW_REMINDER_DETAILS_FAILURE,
    GET_EVENTS, GET_EVENTS_SUCCESS, GET_EVENTS_FAILURE,
    UPDATE_DEPARTMENT_ID, SHOW_TASK_TAB,
    GET_TABS_ENABLE_STATUS, GET_TABS_ENABLE_STATUS_SUCCESS, GET_TABS_ENABLE_STATUS_FAILURE,
    GET_TASK_DATE_BY_MONTH, GET_TASK_DATE_BY_MONTH_SUCCESS, GET_TASK_DATE_BY_MONTH_FAILURE,
} from '../../actions/tasks/tasksAction';
import { createReminder, validateReminderForm } from '../../common/const'

const SAVE_DATES_ARRAY = "SAVE_DATES_ARRAY";
const SAVE_CALENDAR_DATES = "SAVE_CALENDAR_DATES";
const SAVE_ACTIVE_INDEX = "SAVE_ACTIVE_INDEX";
const SAVE_REMINDER_DATES = "SAVE_REMINDER_DATES";
const SAVE_REMINDER_ARRAY = 'SAVE_REMINDER_ARRAY';
const SAVE_CLICKED_MONTH = "SAVE_CLICKED_MONTH";

const INITIAL_STATE = {
    usersList: { usersList: undefined, error: null, loading: false },
    eventsList: { eventsList: undefined, error: null, loading: false },
    datesList: undefined,
    reminderDates: undefined,
    calendarDate: { calendarDate: undefined, error: null, loading: false },
    notificationsList: undefined,
    remindersList: { remindersList: undefined, error: null, loading: false },
    statesList: { statesList: undefined, error: null, loading: false },
    eventTypesList: { eventTypesList: undefined, error: null, loading: false },
    allEventsList: { allEventsList: undefined, error: null, loading: false },
    activeIndex: { activeIndex: undefined, error: null, loading: false },
    reminderData: { reminderData: undefined, error: null, loading: false },
    opportunityList: undefined,
    recordsListData: undefined,
    allRecords: undefined,
    validateReminderFields: validateReminderForm,
    saveReminderForm: createReminder,
    editReminderById: {},
    cancelReminderById: {},
    saveNewReminder: '',
    contactListById: {},
    contactsList: undefined,
    eventsListData: { eventsListData: undefined, error: null, loading: false },
    myDepartmentId: { myDepartmentId: undefined },
    showMyTaskTab: false,
    reminderArrayList : undefined,
    tabEnableStatus: undefined,
    taskDateByMonth: undefined,
    clickedMonth: undefined,
    servicingStateList: []
}
export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case USERS_LISTS:
            return {
                ...state, usersList: {
                    usersList: undefined,
                    error: null,
                    loading: false
                }
            }
        case USERS_LISTS_SUCCESS:
            return {
                ...state, usersList: {
                    usersList: action.payload.data,
                    error: null,
                    loading: false
                }
            }
        case USERS_LISTS_FAILURE:
            return {
                ...state, usersList: {
                    usersList: undefined,
                    error: null,
                    loading: false
                }
            }
        case FETCH_ALL_CONTACTS:
            return {
                ...state,
                contactsList: state.contactsList ? state.contactsList : undefined,
                allRecords: state.allRecords ? state.allRecords : undefined,
                contactsCount: 0,
                error: null,
                loading: true
            }
        case FETCH_ALL_CONTACTS_SUCCESS:
            return {
                ...state,
                contactsList: action.payload.List,
                allRecords: action.payload.List,
                contactsCount: action.payload.TotalCount,
                error: null,
                loading: false
            }
        case FETCH_ALL_CONTACTS_FAILURE:
            return {
                ...state,
                contactsList: undefined,
                allRecords: undefined,
                contactsCount: 0,
                error: null,
                loading: false
            }
        case SAVE_NEW_REMINDER_DETAILS:
            return { ...state, saveNewReminder: '', error: null, loading: true }
        case SAVE_NEW_REMINDER_DETAILS_SUCCESS:
            return { ...state, saveNewReminder: action.payload, error: null, loading: false }
        case SAVE_NEW_REMINDER_DETAILS_FAILURE:
            return { ...state, saveNewReminder: '', error: null, loading: false }
        case FETCH_CONTACT_LIST_BY_ID:
            return {
                ...state,
                contactListById: state.contactListById ? state.contactListById : {},
                error: null,
                loading: true
            }
        case FETCH_CONTACT_LIST_BY_ID_SUCCESS:
            return { ...state, contactListById: action.payload, error: null, loading: false }
        case FETCH_CONTACT_LIST_BY_ID_FAILURE:
            return { ...state, contactListById: "Error", error: null, loading: false }
        case FETCH_ALL_OPPORTUNITIES:
            return {
                ...state,
                opportunityList: state.opportunityList ? state.opportunityList : undefined,

            }
        case FETCH_ALL_OPPORTUNITIES_SUCCESS:
            return { ...state, opportunityList: action.payload.List, }
        case FETCH_ALL_OPPORTUNITIES_FAILURE:
            return { ...state, opportunityList: undefined, }
        case FETCH_ALL_RECORDS:
            return {
                ...state,
                allRecords: state.allRecords ? state.allRecords : undefined,
                recordsCount: 0,
                error: null,
                loading: true
            }
        case FETCH_ALL_RECORDS_SUCCESS:
            return {
                ...state,
                allRecords: action.payload.List,
                recordsCount: action.payload.TotalCount,
                error: null,
                loading: false
            }
        case FETCH_ALL_RECORDS_FAILURE:
            return {
                ...state,
                allRecords: undefined,
                recordsCount: 0,
                error: null,
                loading: false
            }
        case EVENTS_LIST:
            return {
                ...state, eventsList: {
                    eventsList: undefined,
                    error: null,
                    loading: false
                }
            }
        case EVENTS_LIST_SUCCESS:
            return {
                ...state, eventsList: {
                    eventsList: action.payload.data,
                    error: null,
                    loading: false
                }
            }
        case EVENTS_LIST_FAILURE:
            return {
                ...state, eventsList: {
                    eventsList: undefined,
                    error: null,
                    loading: false
                }
            }

        case SAVE_DATES_ARRAY:
            return {
                ...state,
                datesList: action.payload
            }
        case SAVE_REMINDER_DATES:
            return {
                ...state,
                reminderDates: action.payload

            }
        case SAVE_CALENDAR_DATES:
            return {
                ...state, calendarDate: {
                    calendarDate: action.payload,
                    error: null,
                    loading: false
                }
            }

         case SAVE_REMINDER_ARRAY:
                return {
                    ...state, reminderArrayList : action.payload
                }
        case SAVE_ACTIVE_INDEX:
            return {
                ...state, activeIndex: {
                    activeIndex: action.payload,
                    error: null,
                    loading: false
                }
            }
        case NOTIFICATIONS_LISTS:
            return {
                ...state, notificationsList: state.notificationsList ? state.notificationsList : undefined
            }
        case NOTIFICATIONS_LISTS_SUCCESS:
            return {
                ...state,
                notificationsList: action.payload.data
            }
        case NOTIFICATIONS_LISTS_FAILURE:
            return {
                ...state, notificationsList: undefined
            }
        case DELETE_NOTIFICATION_BY_ID:
            return {
                ...state, deleteNotification: {
                    error: null,
                    loading: false
                }
            }
        case DELETE_NOTIFICATION_BY_ID_FAILURE:
            return {
                ...state, deleteNotification: {
                    error: null,
                    loading: false
                }
            }
        case REMINDER_LISTS:
            return {
                ...state, remindersList: {
                    remindersList: undefined,
                    error: null,
                    loading: false
                }
            }
        case REMINDER_LISTS_SUCCESS:
            return {
                ...state, remindersList: {
                    remindersList: action.payload.data,
                    error: null,
                    loading: false
                }
            }
        case REMINDER_LISTS_FAILURE:
            return {
                ...state, remindersList: {
                    remindersList: undefined,
                    error: null,
                    loading: false
                }
            }
        case REMINDER_BY_ID:
            return {
                ...state, reminderData: {
                    reminderData: undefined,
                    error: null,
                    loading: false
                }
            }
        case REMINDER_BY_ID_SUCCESS:
            return {
                ...state, reminderData: {
                    reminderData: action.payload.data,
                    error: null,
                    loading: false
                }
            }
        case REMINDER_BY_ID_FAILURE:
            return {
                ...state, reminderData: {
                    reminderData: undefined,
                    error: null,
                    loading: false
                }
            }
        case UPDATE_VALIDATE_REMINDER:
            return { ...state, validateReminderFields: action.payload.createReminder }
        case EDIT_REMINDER_BY_ID:
            return { ...state, editReminderById: {}, error: null, loading: true }
        case EDIT_REMINDER_BY_ID_SUCCESS:
            return { ...state, editReminderById: action.payload, error: null, loading: false }
        case EDIT_REMINDER_BY_ID_FAILURE:
            return { ...state, editReminderById: {}, error: null, loading: false }
        case CANCEL_REMINDER_BY_ID:
            return { ...state, cancelReminderById: {}, error: null, loading: true }
        case CANCEL_REMINDER_BY_ID_SUCCESS:
            return { ...state, cancelReminderById: action.payload, error: null, loading: false }
        case CANCEL_REMINDER_BY_ID_FAILURE:
            return { ...state, cancelReminderById: {}, error: null, loading: false }
        case UPDATE_SAVE_REMINDER_FORM:
            return { ...state, saveReminderForm: action.payload.createReminder }
        case STATES_LIST:
            return {
                ...state, statesList: {
                    statesList: undefined,
                    error: null,
                    loading: false
                }
            }
        case STATES_LIST_SUCCESS:
            return {
                ...state, statesList: {
                    statesList: action.payload.data,
                    error: null,
                    loading: false
                }
            }
        case STATES_LIST_FAILURE:
            return {
                ...state, statesList: {
                    statesList: undefined,
                    error: null,
                    loading: false
                }
            }
        case EVENT_TYPES_LIST:
            return {
                ...state, eventTypesList: {
                    eventTypesList: undefined,
                    error: null,
                    loading: false
                }
            }
        case EVENT_TYPES_LIST_SUCCESS:
            return {
                ...state, eventTypesList: {
                    eventTypesList: action.payload.data,
                    error: null,
                    loading: false
                }
            }
        case EVENT_TYPES_LIST_FAILURE:
            return {
                ...state, eventTypesList: {
                    eventTypesList: undefined,
                    error: null,
                    loading: false
                }
            }
        case ALL_EVENT_LIST:
            return {
                ...state, allEventsList: {
                    allEventsList: undefined,
                    error: null,
                    loading: false
                }
            }
        case ALL_EVENT_LIST_SUCCESS:
            return {
                ...state, allEventsList: {
                    allEventsList: action.payload,
                    error: null,
                    loading: false
                }
            }
        case ALL_EVENT_LIST_FAILURE:
            return {
                ...state, allEventsList: {
                    allEventsList: undefined,
                    error: null,
                    loading: false
                }
            }


        case GET_EVENTS:
            return {
                ...state, eventsListData: {
                    eventsListData: undefined,
                    error: null,
                    loading: false
                }
            }
        case GET_EVENTS_SUCCESS:
            return {
                ...state, eventsListData: {
                    eventsListData: action.payload.data,
                    error: null,
                    loading: false
                }
            }
        case GET_EVENTS_FAILURE:
            return {
                ...state, eventsListData: {
                    eventsListData: undefined,
                    error: null,
                    loading: false
                }
            }

        case UPDATE_DEPARTMENT_ID:
            return {
                ...state, myDepartmentId: {
                    myDepartmentId: action.payload
                }
            }

        case SHOW_TASK_TAB:
            return {
                ...state, showMyTaskTab: action.payload
            }

            case GET_TABS_ENABLE_STATUS:
                return {
                    ...state, tabEnableStatus: undefined
                }
            case GET_TABS_ENABLE_STATUS_SUCCESS:
                return {
                    ...state, tabEnableStatus: action.payload
                }
            case GET_TABS_ENABLE_STATUS_FAILURE:
                return {
                    ...state, tabEnableStatus: undefined
                }

            case GET_TASK_DATE_BY_MONTH:
            return {
                ...state, taskDateByMonth: undefined
            }
            case GET_TASK_DATE_BY_MONTH_SUCCESS:
                return {
                    ...state, taskDateByMonth: action.payload
                }
            case GET_TASK_DATE_BY_MONTH_FAILURE:
                return {
                    ...state, taskDateByMonth: undefined
                }

            case SAVE_CLICKED_MONTH:
                return {
                    ...state, clickedMonth: action.payload
                }

        default:
            return { ...state }
    }
}
